import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isNil from 'lodash/isNil';

import { PriceAggregate } from '@/api';
import { CardItem } from '@/components/Card/Card';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconInfo from '@/icons/Info.svg';
import IconPay from '@/icons/Pay.svg';
import { ArticleWithRelations } from '@/models/request';
import { Currency, formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

const BaseMoneyCell = ({
  price,
  label,
}: {
  price: PriceAggregate | undefined | null;
  label?: string;
}) => {
  const { isMobile } = useViewPort();

  const hasDiscounts = !!price?.details.some(
    (detail) => detail.type === 'discount' && detail.amount !== 0
  );

  if (isMobile) {
    return (
      <Stack alignItems="flex-end" row gap="0.25rem">
        {label && <p className="paragraph-200-regular text-secondary">{label}</p>}
        {!isNil(price?.amount) ? (
          <Stack
            row
            flexWrap="nowrap"
            gap="0.25rem"
            alignItems="center"
            className="paragraph-200-regular text-primary"
          >
            {formatCurrency(price.amount, price.currency)}
            {hasDiscounts && (
              <s role="deletion" className="paragraph-400-regular text-disabled">
                {formatCurrency(price.details[0]?.amount, price.details[0]?.currency)}
              </s>
            )}
            {hasDiscounts && <DiscountDetail details={price.details} />}
          </Stack>
        ) : (
          <p className="paragraph-200-regular">-</p>
        )}
      </Stack>
    );
  }

  return (
    <Stack alignItems="flex-end">
      {label && <p className="paragraph-200-regular text-secondary">{label}</p>}
      {!isNil(price?.amount) ? (
        <>
          {hasDiscounts && (
            <s role="deletion" className="paragraph-200-regular text-disabled">
              {formatCurrency(price.details[0]?.amount, price.details[0]?.currency)}
            </s>
          )}
          <p
            className="paragraph-100-medium"
            style={{ order: isMobile ? 0 : 1, whiteSpace: 'nowrap' }}
          >
            {hasDiscounts && (
              <>
                <DiscountDetail details={price.details} />{' '}
              </>
            )}
            {formatCurrency(price.amount, price.currency)}
          </p>
        </>
      ) : (
        <p className="paragraph-100-medium">-</p>
      )}
    </Stack>
  );
};

const DiscountDetail = ({ details }: { details: PriceAggregate['details'] }) => {
  const { _ } = useLingui();

  const groupedDiscounts = details.reduce<
    {
      type: 'refashion' | 'rate';
      amount: number;
      currency: Currency;
    }[]
  >((acc, detail) => {
    if (detail.type === 'discount' && detail.amount !== 0) {
      const type = detail.subType === 'refashion' ? 'refashion' : 'rate';
      const existingDiscount = acc.find((discount) => discount.type === type);

      if (existingDiscount) {
        existingDiscount.amount += detail.amount;
      } else {
        acc.push({
          type,
          amount: detail.amount,
          currency: detail.currency,
        });
      }
    }

    return acc;
  }, []);

  const labelForType = {
    refashion: _(msg({ id: 'article-money-cell.refashion', message: 'Bonus amount' })),
    rate: _(msg({ id: 'article-money-cell.discount', message: 'Discount amount' })),
  };

  return (
    <Tooltip
      content={
        <Stack>
          {groupedDiscounts?.map((discount) => (
            <p key={discount.type}>
              {labelForType[discount.type]}: {formatCurrency(discount.amount, discount.currency)}
            </p>
          ))}
        </Stack>
      }
    >
      <Button variant="style-less" style={{ verticalAlign: 'text-top' }}>
        <IconInfo style={{ fontSize: '1rem' }} />
      </Button>
    </Tooltip>
  );
};

export const ArticleCostCardItem = ({
  article,
  costLabel,
}: {
  article: ArticleWithRelations;
  costLabel: string;
}) => {
  return (
    <CardItem>
      <IconPay payOut style={{ fontSize: '1rem' }} />
      <span>
        {costLabel}
        <Trans id="_general.colon">:</Trans>
      </span>
      <BaseMoneyCell price={article.cost} />
    </CardItem>
  );
};

export const ArticlePriceCardItem = ({
  article,
  priceLabel,
}: {
  article: ArticleWithRelations;
  priceLabel: string;
}) => {
  return (
    <CardItem>
      <IconPay payIn style={{ fontSize: '1rem' }} />
      <span>
        {priceLabel}
        <Trans id="_general.colon">:</Trans>
      </span>
      <BaseMoneyCell price={article.price} />
    </CardItem>
  );
};

export const RequestOrganizationPrice = ({
  price,
  label,
}: {
  price: PriceAggregate | undefined | null;
  label?: string;
}) => {
  const { isMobile } = useViewPort();
  const { _ } = useLingui();

  const hasRefashionDiscount = !!price?.details.find(
    (detail) => detail.type === 'discount' && detail.subType === 'refashion' && detail.amount !== 0
  );
  const hasRateDiscounts = !!price?.details.find(
    (detail) => detail.type === 'discount' && detail.subType !== 'refashion' && detail.amount !== 0
  );

  return (
    <Stack
      className="bg-neutral-300"
      gap="0.5rem"
      padding="0.75rem 1rem"
      style={{ borderRadius: '0.5rem' }}
    >
      {label && <span className="paragraph-100-medium text-primary">{label}</span>}

      <Stack>
        <Stack gap="2px">
          <Stack
            row
            flexWrap="nowrap"
            justifyContent="space-between"
            className="paragraph-200-regular text-secondary"
          >
            <p>
              {isMobile ? (
                <Trans id="client.articles.table.footer.subtotal">Subtotal</Trans>
              ) : hasRateDiscounts && hasRefashionDiscount ? (
                <Trans id="client.articles.table.footer.subtotal-bonus-and-discount">
                  Subtotal with discount and Refashion bonus
                </Trans>
              ) : hasRateDiscounts ? (
                <Trans id="client.articles.table.footer.subtotal-discount">
                  Subtotal with discount
                </Trans>
              ) : hasRefashionDiscount ? (
                <Trans id="client.articles.table.footer.subtotal-bonus">
                  Subtotal with Refashion bonus
                </Trans>
              ) : (
                <Trans id="client.articles.table.footer.subtotal">Subtotal</Trans>
              )}
            </p>
            <p style={{ whiteSpace: 'nowrap' }}>
              {!!price && (hasRefashionDiscount || hasRateDiscounts) && (
                <>
                  <DiscountDetail details={price.details} />{' '}
                </>
              )}
              {formatCurrency(price?.amount, price?.currency)}
            </p>
          </Stack>
          <Stack
            row
            flexWrap="nowrap"
            justifyContent="space-between"
            className="paragraph-200-regular text-secondary"
          >
            <p>
              <Trans id="client.articles.table.total.shipment.label">Shipping fees</Trans>
            </p>
            <p>
              <Trans id="client.articles.table.total.shipment.offered">Offered</Trans>
            </p>
          </Stack>
        </Stack>
        <Stack
          row
          flexWrap="nowrap"
          justifyContent="space-between"
          className="paragraph-100-medium text-primary"
          ariaLabel={
            label ??
            _(msg({ id: 'client.articles.table.footer.total-price', message: 'Total price' }))
          }
        >
          <p>
            <Trans id="client.articles.table.footer.total-simple">Total</Trans>
          </p>
          <p>{formatCurrency(price?.amount, price?.currency)}</p>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const RequestWorkshopPrice = ({
  price,
  label,
}: {
  price: PriceAggregate | null | undefined;
  label?: string;
}) => {
  return (
    <Stack
      className="bg-neutral-200"
      gap="0.5rem"
      padding="0.75rem 1rem"
      style={{ borderRadius: '0.5rem' }}
    >
      {label && <span className="paragraph-100-medium text-primary">{label}</span>}

      <Stack
        row
        flexWrap="nowrap"
        justifyContent="space-between"
        className="paragraph-100-medium text-primary"
        ariaLabel={label}
      >
        <p>
          <Trans id="client.articles.table.footer.total-simple">Total</Trans>
        </p>
        <p>{formatCurrency(price?.amount, price?.currency)}</p>
      </Stack>
    </Stack>
  );
};

export default BaseMoneyCell;
