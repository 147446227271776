import { Trans } from '@lingui/macro';

import { Activities } from '@/components/activities/Activities/Activities';
import Box from '@/design_system/Box';
import { ShipmentWithRelations, useInfiniteActivities } from '@/models/shipment';
import { ErrorBoundary } from '@/services/sentry';
import useViewPort from '@/utils/useViewport';

export const ShipmentActivities = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { data, isLoading, isError, fetchNextPage } = useInfiniteActivities({
    shipmentId: shipment.id,
  });
  const activities = data?.pages.flatMap((page) => page.activities) ?? [];

  return (
    <Activities
      activities={activities}
      isLoading={isLoading}
      isError={isError}
      isShipment
      nbOfArticles={shipment.articles.length}
      loadNextPage={() => {
        fetchNextPage();
      }}
    />
  );
};

// TODO: Remove this component when the legacy shipment layout will be removed
export const LegacyShipmentActivities = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { data, isLoading, isError, fetchNextPage } = useInfiniteActivities({
    shipmentId: shipment.id,
  });

  const { isMobile } = useViewPort();
  const activities = data?.pages.flatMap((page) => page.activities) ?? [];

  return (
    <Box removeStyleOnMobile padding="0">
      <h2
        className="headline-300-bold"
        style={{
          padding: isMobile
            ? '0 0 16px 0' /* eslint-disable-line lingui/no-unlocalized-strings */
            : '24px 32px 0 32px' /* eslint-disable-line lingui/no-unlocalized-strings */,
        }}
      >
        <Trans id="shipment.activity.title">Activity</Trans>
      </h2>

      <ErrorBoundary>
        <Activities
          activities={activities}
          isLoading={isLoading}
          isError={isError}
          isShipment
          nbOfArticles={shipment.articles.length}
          loadNextPage={() => {
            fetchNextPage();
          }}
        />
      </ErrorBoundary>
    </Box>
  );
};
