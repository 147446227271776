import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import ClientForm, { getClientError, useClientState } from '@/components/ClientForm';
import Button from '@/design_system/Button';
import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import Stack from '@/design_system/Stack';
import IconArrow from '@/icons/Arrow.svg';
import {
  ClientRequestWithRelations,
  useUpdateRequestClient,
  useUpdateRequestType,
} from '@/models/request';
import { ProgressBar } from '@/routes/Brand/Requests/New/components/ProgressBar/ProgressBar';
import { createBEMClasses } from '@/utils/classname';
import { REQUEST_IN_PROGRESS_LOCAL_STORAGE_KEY } from '@/utils/requestInProgress';
import useViewPort from '@/utils/useViewport';

import './SettingsForm.css';

const { block } = createBEMClasses('client-settings-form');

const SettingsForm = ({
  request,
  onContinue,
}: {
  request: ClientRequestWithRelations;
  onContinue: () => void;
}) => {
  const { _ } = useLingui();
  const navigate = useNavigate();
  const { isMobile } = useViewPort();

  const selectedType = request.client ? 'client' : 'store';
  const { mutateAsync: updateRequestType } = useUpdateRequestType(request.id);
  const { mutateAsync: updateRequestClient, isPending: isPendingUpdateRequestClient } =
    useUpdateRequestClient();

  const [showErrors, setShowErrors] = useState(false);
  const clientState = useClientState(request.client);

  const handleContinue = async () => {
    setShowErrors(true);

    if (selectedType === 'client') {
      if (getClientError(clientState).hasError) {
        return;
      }

      await updateRequestClient({
        id: request.id,
        body: {
          client: {
            name: clientState.name,
            email: clientState.email,
            address: clientState.address ?? undefined,
            billingAddress: clientState.billingAddress ?? undefined,
            phone: clientState.phone,
            deliveryOption: clientState.deliveryOption,
            sendcloudServicePointId: clientState.sendcloudServicePointId,
            locale: clientState.locale,
          },
        },
      });

      onContinue();
    } else if (selectedType === 'store') {
      onContinue();
    }
  };

  const requestTypeOptions = [
    {
      id: 'client',
      text: _(msg({ id: 'client.new.settings.type.client', message: 'Client' })),
    },
    {
      id: 'store',
      text: _(msg({ id: 'client.new.settings.type.store', message: 'Store' })),
    },
  ];

  return (
    <div className={block()}>
      <main>
        <Stack gap="1.5rem">
          <h3 className="headline-300-bold">
            <Trans id="client.new.settings.title">Fill the request information</Trans>
          </h3>
          <Stack gap="1rem">
            <InputSelect
              variant="select"
              label={_(msg({ id: 'client.new.settings.type.label', message: 'Request type' }))}
              aria-label={_(msg({ id: 'client.new.settings.type.label', message: 'Request type' }))}
              isDisabled={!request.draft}
              isSearchable={false}
              options={requestTypeOptions}
              value={requestTypeOptions.find(
                (requestTypeOption) => selectedType === requestTypeOption.id
              )}
              getOptionValue={(requestTypeOption) => requestTypeOption.text}
              getOptionLabel={(requestTypeOption) => requestTypeOption.text}
              onChange={(requestTypeOption) => {
                if (requestTypeOption && requestTypeOption.id !== selectedType) {
                  updateRequestType({ type: requestTypeOption.id as 'client' | 'store' });
                }
              }}
              styleVariant="brand"
              size="large"
            />

            {selectedType === 'client' && (
              <ClientForm
                request={request}
                state={clientState}
                hideVip
                variant="brand"
                size="large"
                showErrors={showErrors}
              />
            )}
          </Stack>
        </Stack>
      </main>
      <footer>
        <ProgressBar progress={5} />
        <Stack row gap={isMobile ? '1rem' : '2rem'}>
          <Button
            size="large"
            variant="secondary-brand"
            style={{ flex: 1 }}
            onPress={() => {
              window.localStorage.removeItem(REQUEST_IN_PROGRESS_LOCAL_STORAGE_KEY);
              navigate(`/brand/${request.organization.slug}/requests/new`);
            }}
          >
            <IconArrow left />
            <Trans id="client.new.settings.action.back">Back</Trans>
          </Button>
          <Button
            size="large"
            variant="brand"
            style={{ flex: 1 }}
            isLoading={isPendingUpdateRequestClient}
            onPress={handleContinue}
          >
            <Trans id="client.new.settings.action.continue">Continue</Trans>
            <IconArrow right />
          </Button>
        </Stack>
      </footer>
    </div>
  );
};

export default SettingsForm;
