import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import Drawer from '@/design_system/Drawer';
import { DrawerBody, DrawerFooter, DrawerHeader } from '@/design_system/Drawer/Drawer';
import Stack from '@/design_system/Stack';
import { type ArticleWithRelations, type RequestWithRelations } from '@/models/request';
import { ArticlePhotoUpload } from '@/routes/Requests/New/components/ArticlePhotoUpload';
import { useCurrentSession } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';

import {
  ProductCategoryL1,
  ProductCategoryL2,
  ProductCategoryL3,
} from './components/ProductCategories';
import ColorSize from './ColorSize';
import { Brand } from './components';
import ProductInfo from './ProductInfo';
import { useArticleErrors } from './useArticleErrors';

export const ArticleInformationFormDrawer = ({
  request,
  article,
  isOpen,
  closeArticleDrawer,
  showErrors,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  isOpen: boolean;
  showErrors: boolean;
  closeArticleDrawer: () => void;
}) => {
  const { currentSession } = useCurrentSession();
  const { productError, productL2Error, otherBrandError } = useArticleErrors(article, request);

  return (
    <Drawer
      isOpen={isOpen}
      onOpenChange={closeArticleDrawer}
      style={{
        maxWidth: '31.25rem',
      }}
    >
      <DrawerHeader>
        <h1 className="headline-300-bold">
          <Trans id="article.edit.title">Edit information</Trans>
        </h1>
      </DrawerHeader>

      <DrawerBody>
        <ErrorBoundary>
          <Stack gap="1rem" padding="1rem 1.5rem">
            <ArticlePhotoUpload article={article} />

            {!currentSession?.workshop?.external && (
              <ProductInfo
                request={request}
                article={article}
                row={false}
                key={article.productId}
                showReference
                error={showErrors && !!productError ? productError : undefined}
              />
            )}

            <ProductCategoryL1
              request={request}
              article={article}
              l1Error={showErrors && !!productError ? productError : undefined}
            />

            <ProductCategoryL2
              request={request}
              article={article}
              l2Error={showErrors && !!productL2Error ? productL2Error : undefined}
            />

            {request.organization.brands.length && (
              <Brand request={request} article={article} otherBrandError={otherBrandError} />
            )}

            <ProductCategoryL3 request={request} article={article} />

            <ColorSize request={request} article={article} />
          </Stack>
        </ErrorBoundary>
      </DrawerBody>

      <DrawerFooter>
        <Button onPress={closeArticleDrawer}>
          <Trans id="article.edit.save">Save</Trans>
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};
