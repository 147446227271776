import { Stepper } from '@/design_system/Stepper/Stepper';
import { ShipmentWithRelations } from '@/models/shipment';
import { useShipmentStepsToDisplay } from '@/routes/Shipments/Shipment/components/ShipmentStep/ShipmentStep';
import useViewPort from '@/utils/useViewport';

const ShipmentStepper = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { isMobile } = useViewPort();

  const steps = useShipmentStepsToDisplay(shipment);

  return <Stepper size={isMobile ? 'small' : 'large'} showStepCount={isMobile} steps={steps} />;
};

export default ShipmentStepper;
