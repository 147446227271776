import { RequestWithRelations } from '@/models/request';
import { ArchivedMessage } from '@/routes/Requests/Request/components/RequestStepMessage/components/ArchivedMessage/ArchivedMessage';
import PaymentMessage from '@/routes/Requests/Request/components/RequestStepMessage/components/PaymentMessage';
import TransitMessage from '@/routes/Requests/Request/components/RequestStepMessage/components/TransitMessage';
import ValidationMessage from '@/routes/Requests/Request/components/RequestStepMessage/components/ValidationMessage';

export const RequestStepMessage = ({ request }: { request: RequestWithRelations }) => {
  if (request.isArchived) {
    return <ArchivedMessage request={request} />;
  }

  if (request.isInValidationStep) {
    return <ValidationMessage request={request} />;
  }

  if (request.isInPaymentStep) {
    return <PaymentMessage request={request} />;
  }

  const atLeastOneItemInPendingClientPickup = request.articles.some(
    (article) =>
      article.step?.step === 'transit' &&
      article.step?.config.originType === 'store' &&
      article.step?.config.destinationType === 'client'
  );

  // request.isInPendingClientDeliveryStep is still used to handle pre-workflow requests.
  // It should be removed when all legacy requests are archived.

  if (atLeastOneItemInPendingClientPickup || request.isInPendingClientDeliveryStep) {
    return <TransitMessage request={request} />;
  }

  return null;
};
