import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isNil from 'lodash/isNil';

import { PriceAggregate } from '@/api';
import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import { InputMoney } from '@/design_system/InputNumber';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import { CustomActionTypeArticle } from '@/models/actionType';
import { ArticleCustomAction } from '@/models/request';
import { useCurrentSession } from '@/services/auth';
import { Currency } from '@/utils/number';

const CustomActionDialog = ({
  isOpen,
  onClose,
  onAddAction,
  onEditAction,
  mode,
  showWorkshopPrice,
  showOrganizationPrice,
  workshopPriceCurrency,
  organizationPriceCurrency,
  initialCustomAction,
  isWorkshopPriceDisabled,
}: {
  isOpen: boolean;
  onClose: () => void;
  onAddAction: (action: ArticleCustomAction) => void;
  onEditAction: (action: ArticleCustomAction) => void;
  mode: 'need' | 'action' | 'both';
  showWorkshopPrice?: boolean;
  showOrganizationPrice?: boolean;
  workshopPriceCurrency?: Currency;
  organizationPriceCurrency?: Currency;
  initialCustomAction?: ArticleCustomAction;
  isWorkshopPriceDisabled?: boolean;
}) => {
  const { _ } = useLingui();

  return (
    <Dialog
      title={
        initialCustomAction
          ? _(msg({ id: 'article.form.custom-action.dialog.title.edit', message: 'Edit action' }))
          : _(msg({ id: 'article.form.custom-action.dialog.title', message: 'Add a new action' }))
      }
      isOpen={isOpen}
      onOpenChange={onClose}
    >
      <CustomActionForm
        onCancel={onClose}
        onAddAction={onAddAction}
        onEditAction={onEditAction}
        mode={mode}
        showWorkshopPrice={showWorkshopPrice}
        showOrganizationPrice={showOrganizationPrice}
        workshopPriceCurrency={workshopPriceCurrency}
        organizationPriceCurrency={organizationPriceCurrency}
        initialCustomAction={initialCustomAction}
        isWorkshopPriceDisabled={isWorkshopPriceDisabled}
      />
    </Dialog>
  );
};

const CustomActionForm = ({
  onCancel,
  onAddAction,
  onEditAction,
  mode,
  showWorkshopPrice,
  showOrganizationPrice,
  workshopPriceCurrency = 'EUR',
  organizationPriceCurrency = 'EUR',
  initialCustomAction,
  isWorkshopPriceDisabled,
}: {
  onCancel: () => void;
  onAddAction: (action: ArticleCustomAction) => void;
  onEditAction: (action: ArticleCustomAction) => void;
  mode: 'need' | 'action' | 'both';
  showWorkshopPrice?: boolean;
  showOrganizationPrice?: boolean;
  workshopPriceCurrency?: Currency;
  organizationPriceCurrency?: Currency;
  initialCustomAction?: ArticleCustomAction;
  isWorkshopPriceDisabled?: boolean;
}) => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();

  const isWorkshop = !!currentSession?.workshop;

  const [description, setDescription] = useState(initialCustomAction?.description ?? '');
  const [workshopPrice, setWorkshopPrice] = useState<number | undefined>(
    initialCustomAction?.costAmountWithoutDiscount ?? undefined
  );
  const [organizationPrice, setOrganizationPrice] = useState<number | undefined>(
    initialCustomAction?.priceAmountWithoutDiscount ?? undefined
  );

  const [showErrors, setShowErrors] = useState(false);

  const isDescriptionInvalid = !description;
  const isWorkshopPriceInvalid = isNil(workshopPrice) || Number.isNaN(workshopPrice);
  const isOrganizationPriceInvalid = isNil(organizationPrice) || Number.isNaN(organizationPrice);

  const onSave = () => {
    if (
      isDescriptionInvalid ||
      (mode !== 'need' && showWorkshopPrice && isWorkshopPriceInvalid) ||
      (mode !== 'need' && showOrganizationPrice && isOrganizationPriceInvalid)
    ) {
      setShowErrors(true);
      return;
    }

    const fields = {
      description,
      costAmountWithoutDiscount: mode !== 'need' && showWorkshopPrice ? workshopPrice : undefined,
      costCurrency: mode !== 'need' && showWorkshopPrice ? workshopPriceCurrency : undefined,
      priceAmountWithoutDiscount:
        mode !== 'need' && showOrganizationPrice ? organizationPrice : undefined,
      priceCurrency:
        mode !== 'need' && showOrganizationPrice ? organizationPriceCurrency : undefined,
    };

    if (initialCustomAction) {
      onEditAction(Object.assign(initialCustomAction, fields));
    } else {
      const workshopPriceAggregate = {
        amount: workshopPrice ?? 0,
        currency: workshopPriceCurrency,
        details: [],
      } as unknown as PriceAggregate;

      onAddAction(
        new CustomActionTypeArticle({
          ...fields,
          quantity: 1,
          id: crypto.randomUUID(),
        })
          // Front-end only action price to be displayed on ActionsTable in the requalification dialog
          .with('price', isWorkshop ? workshopPriceAggregate : undefined)
          .with('cost', undefined)
      );
    }
  };

  return (
    <>
      <main>
        <Stack gap="1rem">
          <TextArea
            label={_(
              msg({
                id: 'article.form.custom-action.dialog.description.label',
                message: 'Description',
              })
            )}
            placeholder={_(
              msg({
                id: 'article.form.custom-action.dialog.description.placeholder',
                message: 'Describe the action needed for the item...',
              })
            )}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={
              showErrors && isDescriptionInvalid
                ? _(
                    msg({
                      id: 'article.form.custom-action.dialog.description.error',
                      message: 'Please add an action description',
                    })
                  )
                : undefined
            }
          />
          {mode !== 'need' && showWorkshopPrice && (
            <InputMoney
              label={
                isWorkshop
                  ? _(
                      msg({
                        id: 'article.form.custom-action.dialog.price.label',
                        message: 'Price',
                      })
                    )
                  : _(
                      msg({
                        id: 'article.form.custom-action.dialog.internal-cost.label',
                        message: 'Internal cost',
                      })
                    )
              }
              placeholder={
                isWorkshop
                  ? _(
                      msg({
                        id: 'article.form.custom-action.dialog.client-price.placeholder',
                        message: 'Add a price for the action...',
                      })
                    )
                  : _(
                      msg({
                        id: 'article.form.custom-action.dialog.internal-cost.placeholder',
                        message: 'Add a cost for the action...',
                      })
                    )
              }
              value={workshopPrice}
              onChange={setWorkshopPrice}
              currency={workshopPriceCurrency}
              error={
                showErrors && isWorkshopPriceInvalid
                  ? isWorkshop
                    ? _(
                        msg({
                          id: 'article.form.custom-action.dialog.price.error',
                          message: 'Please add a price',
                        })
                      )
                    : _(
                        msg({
                          id: 'article.form.custom-action.dialog.internal-cost.error',
                          message: 'Please add an internal cost',
                        })
                      )
                  : undefined
              }
              isDisabled={isWorkshopPriceDisabled}
            />
          )}
          {mode !== 'need' && showOrganizationPrice && (
            <InputMoney
              label={_(
                msg({
                  id: 'article.form.custom-action.dialog.client-price.label',
                  message: 'Client price',
                })
              )}
              placeholder={_(
                msg({
                  id: 'article.form.custom-action.dialog.client-price.placeholder',
                  message: 'Add a price for the action...',
                })
              )}
              value={organizationPrice}
              onChange={setOrganizationPrice}
              currency={organizationPriceCurrency}
              error={
                showErrors && isOrganizationPriceInvalid
                  ? _(
                      msg({
                        id: 'article.form.custom-action.dialog.client-price.error',
                        message: 'Please add a client price',
                      })
                    )
                  : undefined
              }
            />
          )}
        </Stack>
      </main>
      <footer>
        <Button variant="secondary" onPress={onCancel}>
          <Trans id="article.form.custom-action.dialog.cancel">Cancel</Trans>
        </Button>
        <Button variant="primary" onPress={onSave}>
          {initialCustomAction ? (
            <Trans id="article.form.custom-action.dialog.save">Save</Trans>
          ) : (
            <Trans id="article.form.custom-action.dialog.add">Add a new action</Trans>
          )}
        </Button>
      </footer>
    </>
  );
};

export default CustomActionDialog;
