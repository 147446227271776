import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import { useDispatch } from '@/models/article';
import { ArticleWithRelations } from '@/models/request';

export const DispatchArticleAction = ({
  article,
  onActionDone,
  onCheckErrors,
}: {
  article: ArticleWithRelations;
  onActionDone: () => void;
  onCheckErrors: () => boolean;
}) => {
  const { _ } = useLingui();
  const [error, setError] = useState<string | null>(null);

  const {
    mutateAsync: dispatchArticle,
    isPending: isPendingDispatch,
    isSuccess: isSuccessDispatch,
  } = useDispatch({ articleId: article.id });

  const handleDispatch = async () => {
    setError(null);
    const hasErrors = onCheckErrors();

    if (!hasErrors) {
      try {
        await dispatchArticle();
        onActionDone();
      } catch (err: any) {
        console.error(err);
        setError(
          (err.message as string) ??
            _(msg({ id: '_general.error.unknown', message: 'Unknown error' }))
        );
      }
    }
  };

  return (
    <Stack gap="1rem" row>
      {error && <p className="paragraph-100-medium text-danger">{error}</p>}
      <Button
        variant="primary"
        size="medium"
        onPress={handleDispatch}
        isLoading={isPendingDispatch || isSuccessDispatch}
      >
        <Trans id="article.actions.dispatch_article.send">
          Send to the workshop for validation
        </Trans>
      </Button>
    </Stack>
  );
};
