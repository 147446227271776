import RefashionLogo, { RefashionStatus } from '@/components/RefashionLogo';
import Stack from '@/design_system/Stack';
import { ActionType } from '@/models/actionType';
import { useDatabaseTranslation } from '@/models/translation';

const ActionName = ({
  actionType,
  refashionStatus,
  mode,
}: {
  actionType: ActionType;
  refashionStatus?: RefashionStatus | null;
  mode: 'need' | 'action' | 'both';
}) => {
  const { _db } = useDatabaseTranslation();

  return (
    <Stack>
      <p className="paragraph-100-medium">
        {_db(mode === 'need' ? actionType.needName : actionType.name)}
        {!!refashionStatus && (
          <>
            {' '}
            <RefashionLogo status={refashionStatus} />
          </>
        )}
      </p>
      {mode === 'both' && <p className="paragraph-200-regular">{_db(actionType.needName)}</p>}
    </Stack>
  );
};

export default ActionName;
