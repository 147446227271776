import { ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import { GenericError } from '@/components/GenericError/GenericError';
import IllustrationError from '@/components/IllustrationError';
import Loader from '@/components/Loader';
import {
  PageLayout,
  PageLayoutCenter,
  PageLayoutContent,
  PageLayoutHeader,
  PageLayoutLeftPart,
  PageLayoutLeftPartContent,
  PageLayoutLeftPartFooter,
  PageLayoutRightPart,
  PageLayoutTopPart,
} from '@/components/PageLayout';
import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import { ShipmentWithRelations, useShipment } from '@/models/shipment';
import { ShipmentActivities } from '@/routes/Shipments/Shipment/components/Activities/ShipmentActivities';
import FinalisationAction from '@/routes/Shipments/Shipment/components/FinalisationAction';
import HandoverAction from '@/routes/Shipments/Shipment/components/HandoverAction';
import InTransitAction from '@/routes/Shipments/Shipment/components/InTransitAction';
import PreparationActions from '@/routes/Shipments/Shipment/components/PreparationActions';
import { ShipmentArticles } from '@/routes/Shipments/Shipment/components/ShipmentArticles/ShipmentArticles';
import { ShipmentBreadcrumb } from '@/routes/Shipments/Shipment/components/ShipmentBreadcrumb';
import { ShipmentComments } from '@/routes/Shipments/Shipment/components/ShipmentComments/ShipmentComments';
import { ShipmentHeader } from '@/routes/Shipments/Shipment/components/ShipmentHeader';
import {
  ShipmentInfoSidePanel,
  ShipmentInfoTopPanel,
} from '@/routes/Shipments/Shipment/components/ShipmentInfo';
import { ShipmentOverview } from '@/routes/Shipments/Shipment/components/ShipmentOverview';
import { ShipmentStep } from '@/routes/Shipments/Shipment/components/ShipmentStep';
import VerificationActions from '@/routes/Shipments/Shipment/components/VerificationActions';
import { useCurrentSession } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';
import { createBEMClasses } from '@/utils/classname';
import { NotFoundError } from '@/utils/error/NotFoundError';
import { useResetSimpleBlocker } from '@/utils/navigation';
import { refreshBrowserTabTitle } from '@/utils/refreshBrowserTabTitle';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

import './Shipment.css';

const { block, element } = createBEMClasses('shipment');

export const Shipment = () => {
  const { isMobile } = useViewPort();
  const { id } = useParams();
  const { data: shipment, isLoading, isError, error } = useShipment(id);
  const [commentsRef, scrollCommentsIntoView] = useScrollIntoView<HTMLDivElement>();
  const [containerRef, scrollContainerIntoView] = useScrollIntoView<HTMLDivElement>();

  // Display the shipment reference in the browser tab title
  useEffect(() => {
    if (!shipment) {
      return;
    }

    const shipmentDetails =
      shipment.destinationClient?.name ??
      shipment.destinationStore?.name ??
      shipment.destinationWorkshop?.name;
    const shipmentReference = shipmentDetails
      ? `${shipment.reference} - ${shipmentDetails}`
      : shipment.reference;

    document.querySelector('meta[name="subtitle"]')?.setAttribute('content', shipmentReference);
    refreshBrowserTabTitle();

    return () => {
      document.querySelector('meta[name="subtitle"]')?.setAttribute('content', '');
      refreshBrowserTabTitle();
    };
  }, [shipment]);

  // Clear router blocker state after coming from shipments/new or shipment/edit
  useResetSimpleBlocker();

  if (isLoading) {
    return (
      <PageLayoutCenter>
        <Loader style={{ height: '40px', width: '40px' }} />
        <p className="paragraph-100-regular">
          <Trans id="_general.loading">Loading...</Trans>
        </p>
      </PageLayoutCenter>
    );
  }

  if (isError || !shipment) {
    return <ShipmentError error={error} />;
  }

  return (
    <PageLayout className={block()}>
      <PageLayoutHeader>
        <ShipmentHeader shipment={shipment} onCommentButtonPress={scrollCommentsIntoView} />
      </PageLayoutHeader>
      <PageLayoutContent>
        <PageLayoutLeftPart>
          <PageLayoutLeftPartContent>
            {isMobile && (
              <PageLayoutTopPart>
                <ShipmentInfoTopPanel shipment={shipment} />
              </PageLayoutTopPart>
            )}
            <Stack className={element('main')} ref={containerRef}>
              {!isMobile && (
                <>
                  <ShipmentBreadcrumb shipment={shipment} />
                  <Box style={{ backgroundColor: 'var(--color-neutral-0)' }}>
                    <Stack gap="1rem">
                      <ShipmentOverview shipment={shipment} />
                      <ShipmentStep shipment={shipment} />
                    </Stack>
                  </Box>
                </>
              )}
              {isMobile && <ShipmentStep shipment={shipment} />}

              <Stack gap="0.5rem">
                {isMobile && (
                  <h2 className="headline-400-bold" style={{ marginTop: '1rem' }}>
                    <Trans id="shipment.items.title">Items</Trans>
                  </h2>
                )}

                <ErrorBoundary>
                  <ShipmentArticles shipment={shipment} />
                </ErrorBoundary>
              </Stack>

              <Stack gap="2rem" style={{ marginTop: isMobile ? '0.5rem' : '1rem' }}>
                <Stack gap={isMobile ? undefined : '0.5rem'} ref={commentsRef}>
                  <h2 className="headline-400-bold">
                    <Trans id="shipment.comment.title">Comments</Trans>
                  </h2>

                  <ErrorBoundary>
                    <Box
                      className={element('comment', { 'no-background': isMobile })}
                      padding="0"
                      removeStyleOnMobile
                    >
                      <ShipmentComments shipmentId={shipment.id} />
                    </Box>
                  </ErrorBoundary>
                </Stack>

                <Stack gap={isMobile ? undefined : '0.5rem'}>
                  <h2 className="headline-400-bold">
                    <Trans id="shipment.activity.title">Activity</Trans>
                  </h2>

                  <ErrorBoundary>
                    <Box
                      className={element('activity', { 'no-background': isMobile })}
                      padding="0"
                      removeStyleOnMobile
                    >
                      <ShipmentActivities shipment={shipment} />
                    </Box>
                  </ErrorBoundary>
                </Stack>
              </Stack>
            </Stack>
          </PageLayoutLeftPartContent>
          <ShipmentActionsFooter shipment={shipment} onActionDone={scrollContainerIntoView} />
        </PageLayoutLeftPart>
        {!isMobile && (
          <PageLayoutRightPart>
            <ShipmentInfoSidePanel shipment={shipment} />
          </PageLayoutRightPart>
        )}
      </PageLayoutContent>
    </PageLayout>
  );
};

const ShipmentError = ({ error }: { error: Error | null }) => {
  let errorMessage = <GenericError />;

  if (error instanceof NotFoundError) {
    errorMessage = (
      <p className="paragraph-100-regular">
        <Trans id="shipment.error.not-found">
          Unknown shipment or you do not have access to it.
        </Trans>
      </p>
    );
  }

  return (
    <PageLayoutCenter>
      <IllustrationError />
      {errorMessage}
    </PageLayoutCenter>
  );
};

export const ShipmentActionsFooter = ({
  shipment,
  onActionDone,
}: {
  shipment: ShipmentWithRelations;
  onActionDone: () => void;
}) => {
  let action: ReactNode = null;

  const { currentSession } = useCurrentSession();

  const canCreateShipment = shipment.canBeCreatedBy(currentSession);
  const canVerifyReception = shipment.canReceptionBeVerifiedBy(currentSession);

  // This canXXX extra checks are also kept inside the specific components for compatibility purpose
  // when the LegacyShipment will be removed we'll clean this
  if (shipment.step === 'preparation' && canCreateShipment) {
    action = <PreparationActions shipment={shipment} onValidationDone={onActionDone} />;
  } else if (shipment.step === 'finalisation' && canCreateShipment) {
    action = <FinalisationAction shipment={shipment} onFinalisationDone={onActionDone} />;
  } else if (
    shipment.step === 'handover' &&
    ((canCreateShipment && shipment.handover) || canVerifyReception)
  ) {
    action = <HandoverAction shipment={shipment} onHandOverDone={onActionDone} />;
  } else if (shipment.step === 'in-transit' && canVerifyReception) {
    action = <InTransitAction shipment={shipment} onTransitDone={onActionDone} />;
  } else if (shipment.step === 'verification' && canVerifyReception) {
    action = <VerificationActions shipment={shipment} onVerificationDone={onActionDone} />;
  }

  if (!action) {
    return null;
  }

  return <PageLayoutLeftPartFooter>{action}</PageLayoutLeftPartFooter>;
};
