import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Chip from '@/design_system/Chip';
import LegacyInputMultiSelect from '@/design_system/InputMultiSelect';
import { BasicMultiSelectItem } from '@/design_system/InputMultiSelect/LegacyInputMultiSelect';
import { SHIPMENT_STEPS, ShipmentStep } from '@/models/shipment';

export const ShipmentStepSelect = ({
  selectedKeys,
  onSelectionChange,
}: {
  selectedKeys: ShipmentStep[];
  onSelectionChange: (keys: ShipmentStep[]) => void;
}) => {
  const { _ } = useLingui();

  return (
    <LegacyInputMultiSelect
      placeholder={_(msg({ id: 'shipments.filter.status.placeholder', message: 'Status: All' }))}
      ariaLabel={_(msg({ id: 'shipments.filter.status.label', message: 'Search by status' }))}
      size="large"
      style={{ minWidth: 175 }}
      onSelectionChange={(keys) => {
        onSelectionChange([...keys] as ShipmentStep[]);
      }}
      selectedKeys={selectedKeys}
      chips={SHIPMENT_STEPS.filter((step) => selectedKeys.includes(step.id)).map((step) => (
        <Chip
          key={step.id}
          size="x-small"
          onCancel={() => {
            onSelectionChange(selectedKeys.filter((s) => s !== step.id));
          }}
        >
          {_(step.name)}
        </Chip>
      ))}
    >
      {SHIPMENT_STEPS.map((step) => (
        <BasicMultiSelectItem key={step.id} id={step.id} text={_(step.name)} />
      ))}
    </LegacyInputMultiSelect>
  );
};
