import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import FileUpload from '@/components/FileUpload';
import InputQuantity from '@/components/InputQuantity';
import Button from '@/design_system/Button';
import RadioButtonGroup, {
  yesNoRadioOptions,
} from '@/design_system/RadioButtonGroup/RadioButtonGroup';
import IconAdd from '@/icons/Add.svg';
import IconEdit from '@/icons/Edit.svg';
import { Medium, useMedia } from '@/models/medium';
import { ArticleAction, ArticleCustomAction, ArticlePackAction } from '@/models/request';
import useViewPort from '@/utils/useViewport';

export const BrandResponsibilityCell = ({
  value,
  onChange,
  size = 'large',
  isDisabled,
}: {
  value: boolean;
  onChange?: (value: boolean) => void;
  size?: 'small' | 'large';
  isDisabled?: boolean;
}) => {
  const { _ } = useLingui();

  const selectedOptionValue = value ? 'yes' : 'no';

  if (isDisabled) {
    return (
      <p className={size === 'small' ? 'paragraph-200-regular' : 'paragraph-100-regular'}>
        {_(yesNoRadioOptions.find((option) => option.value === selectedOptionValue)!.children)}
      </p>
    );
  }

  return (
    <RadioButtonGroup
      ariaLabel={_(
        msg({
          id: 'article.form.actions.table.column.brand-responsibility.label',
          message: 'Brand responsibility:',
        })
      )}
      value={selectedOptionValue}
      onChange={(option) => onChange?.(option === 'yes')}
      options={yesNoRadioOptions.map((option) => ({ ...option, children: _(option.children) }))}
      size={size}
    />
  );
};

export const QuantityCell = ({
  quantity,
  onDecrement,
  onIncrement,
  isDisabled,
  disableAdd,
}: {
  quantity: number;
  onDecrement?: () => void;
  onIncrement?: () => void;
  isDisabled?: boolean;
  disableAdd?: boolean;
}) => {
  const { _ } = useLingui();

  return (
    <InputQuantity
      quantity={quantity}
      onDecrement={() => {
        if (quantity > 1) {
          onDecrement?.();
          return;
        }

        if (
          confirm(
            _(
              msg({
                id: 'action-tables.quantity.comfirm-deletion',
                message:
                  'Are you sure you want to delete this action? The associated photos will be deleted as well.',
              })
            )
          )
        ) {
          onDecrement?.();
        }
      }}
      onIncrement={onIncrement}
      isDisabled={isDisabled}
      disableAdd={disableAdd}
      size="small"
    />
  );
};

export const EditCustomActionCell = ({ onPress }: { onPress?: () => void }) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  if (!onPress) {
    return null;
  }

  return (
    <Button
      variant="secondary"
      size={isMobile ? 'small' : 'medium'}
      iconOnly
      tooltip={_(
        msg({ id: 'article.form.actions.table.column.edit.label', message: 'Edit action' })
      )}
      ariaLabel={_(
        msg({ id: 'article.form.actions.table.column.edit.label', message: 'Edit action' })
      )}
      onPress={onPress}
    >
      <IconEdit />
    </Button>
  );
};

export const DefectPhotosCell = ({
  action,
  size = 'medium',
  onUploadDefectPhoto,
  onDeleteDefectPhoto,
  isDisabled,
  isRequalification,
}: {
  action: ArticleAction | ArticleCustomAction | ArticlePackAction;
  size?: 'small' | 'medium' | 'large';
  onUploadDefectPhoto?: (actionId: string, defectPhotoId: string) => void;
  onDeleteDefectPhoto?: (actionId: string, defectPhotoId: string) => void;
  isDisabled?: boolean;
  isRequalification?: boolean;
}) => {
  const { _ } = useLingui();

  const actionType =
    'packActionTypeOrganizationId' in action
      ? 'packActionId'
      : 'description' in action
        ? 'customActionId'
        : 'actionId';

  const { data: { media } = { media: [] } } = useMedia({ [actionType]: action.id });
  const [localMediaState, setLocalMediaState] = useState<Medium[]>([]);

  return (
    <FileUpload
      variant="grid"
      size={size}
      uploadData={{
        type: isRequalification ? 'requalification-defect-photo' : 'defect-photo',
        [actionType]: isRequalification ? undefined : action.id,
      }}
      icon={<IconAdd />}
      ariaLabel={_(
        msg({
          id: 'article.forms.actions.defect-photo.prompt',
          message: 'Add defect photos',
        })
      )}
      prompt={<Trans id="article.forms.actions.defect-photo.prompt">Add defect photos</Trans>}
      media={isRequalification ? localMediaState : media}
      deleteWithApi
      onUpload={(defectPhoto) => {
        if (isRequalification) {
          onUploadDefectPhoto?.(action.id, defectPhoto.id);
          setLocalMediaState((prevMedia) => [...prevMedia, defectPhoto]);
        }
      }}
      onDelete={(defectPhotoId) => {
        if (isRequalification) {
          onDeleteDefectPhoto?.(action.id, defectPhotoId);
          setLocalMediaState((prevMedia) =>
            prevMedia.filter((media) => media.id !== defectPhotoId)
          );
        }
      }}
      disabled={isDisabled}
    />
  );
};
