import { Fragment, useContext } from 'react';
import { GridList, GridListItem } from 'react-aria-components';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ArticleNameCell } from '@/components/ArticlesTableCells';
import { ArticleDeleteArticleActionCell } from '@/components/ArticlesTableCells/ArticleDeleteArticleActionCell';
import ArticleIssueCell from '@/components/ArticlesTableCells/ArticleIssueCell';
import { ArticlePrintArticleSheetActionCell } from '@/components/ArticlesTableCells/ArticlePrintArticleSheetActionCell';
import ArticleReceivedVerificationActionCell from '@/components/ArticlesTableCells/ArticleReceivedVerificationActionCell';
import ArticleReceivedVerificationStatusCell from '@/components/ArticlesTableCells/ArticleReceivedVerificationStatusCell';
import ArticleRequestCell, {
  ArticleRequestCardItem,
} from '@/components/ArticlesTableCells/ArticleRequestCell/ArticleRequestCell';
import ArticleWeightCell, {
  ArticleWeightCardItem,
} from '@/components/ArticlesTableCells/ArticleWeightCell';
import { Card, CardActions, CardContent, CardItems } from '@/components/Card/Card';
import Stack from '@/design_system/Stack';
import Table, { Body, Cell, Column, Header, Row } from '@/design_system/Table/Table';
import { useArticleName } from '@/models/article';
import { ShipmentWithRelations } from '@/models/shipment';
import { ShipmentArticlesContext } from '@/routes/Shipments/Shipment/components/ShipmentArticles/ShipmentArticles';

type ShipmentArticle = ShipmentWithRelations['articles'][number];

export const ArticlesTable = ({ shipmentArticles }: { shipmentArticles: ShipmentArticle[] }) => {
  const { _ } = useLingui();

  return (
    <Table
      aria-label={_(msg({ id: 'shipment.articles.table.label', message: 'Items' }))}
      extraSidePadding
    >
      <ArticlesTableHeader />
      <Body>
        {shipmentArticles.map((shipmentArticle) => (
          <Fragment key={shipmentArticle.articleId}>
            <ArticlesTableRow shipmentArticle={shipmentArticle} />
            {shipmentArticle.issue && <ArticleIssueRow shipmentArticle={shipmentArticle} />}
          </Fragment>
        ))}
      </Body>
    </Table>
  );
};

const ArticlesTableHeader = () => {
  const {
    showVerificationStatus,
    showVerificationActions,
    showPrintArticleSheetActions,
    showDeleteArticleAction,
  } = useContext(ShipmentArticlesContext);

  return (
    <Header>
      <Column isRowHeader width="3fr" minWidth={150}>
        <Trans id="shipment.articles.table.articles.label">Items</Trans>
      </Column>
      {showVerificationStatus && (
        <Column minWidth={150} width="2fr">
          <Trans id="shipment.articles.table.status.label">Status</Trans>
        </Column>
      )}
      <Column width="2fr" minWidth={200} align="start">
        <Trans id="shipment.articles.table.request.label">Request</Trans>
      </Column>
      <Column>
        <Trans id="shipment.articles.table.weight.label">Weight</Trans>
      </Column>
      {(showVerificationActions || showPrintArticleSheetActions || showDeleteArticleAction) && (
        <Column minWidth={100} />
      )}
    </Header>
  );
};

const ArticlesTableRow = ({ shipmentArticle }: { shipmentArticle: ShipmentArticle }) => {
  const {
    showVerificationStatus,
    showVerificationActions,
    showPrintArticleSheetActions,
    showDeleteArticleAction,
    shipment,
  } = useContext(ShipmentArticlesContext);

  const article = shipmentArticle.article;

  return (
    <Row id={article.id} noBorder={!!shipmentArticle.issue}>
      <Cell>
        <ArticleNameCell article={article} />
      </Cell>
      {showVerificationStatus && (
        <Cell>
          <ArticleReceivedVerificationStatusCell shipmentArticle={shipmentArticle} />
        </Cell>
      )}
      <Cell align="start">
        <ArticleRequestCell article={article} organization={shipment.organization} />
      </Cell>
      <Cell>
        <ArticleWeightCell article={article} />
      </Cell>
      {showVerificationActions && (
        <Cell align="end">
          <ArticleReceivedVerificationActionCell
            shipmentArticle={shipmentArticle}
            shipment={shipment}
            key={`${shipmentArticle.verified}`}
          />
        </Cell>
      )}

      {(showPrintArticleSheetActions || showDeleteArticleAction) && (
        <Cell align="end">
          <Stack row gap="0.5rem">
            {showPrintArticleSheetActions && (
              <ArticlePrintArticleSheetActionCell
                article={article}
                organization={shipment.organization}
              />
            )}
            {showDeleteArticleAction && (
              <ArticleDeleteArticleActionCell
                articleId={article.id}
                shipmentId={shipment.id}
                isDisabled={shipment.articles.length === 1}
              />
            )}
          </Stack>
        </Cell>
      )}
    </Row>
  );
};

const ArticleIssueRow = ({ shipmentArticle }: { shipmentArticle: ShipmentArticle }) => {
  const { showVerificationStatus, showVerificationActions } = useContext(ShipmentArticlesContext);

  const span = 3 + (showVerificationStatus ? 1 : 0) + (showVerificationActions ? 1 : 0);
  return (
    <Row>
      <Cell colSpan={span} style={{ paddingTop: 0 }} align="stretch">
        <ArticleIssueCell issue={shipmentArticle.issue ?? ''} />
      </Cell>
    </Row>
  );
};

export const ArticlesCardList = ({ shipmentArticles }: { shipmentArticles: ShipmentArticle[] }) => {
  const { _ } = useLingui();

  return (
    <GridList
      aria-label={_(
        msg({
          id: 'shipment.articles.table.label',
          message: 'Items',
        })
      )}
    >
      {shipmentArticles.map((shipmentArticle) => (
        <ArticleCard key={shipmentArticle.articleId} shipmentArticle={shipmentArticle} />
      ))}
    </GridList>
  );
};

const ArticleCard = ({ shipmentArticle }: { shipmentArticle: ShipmentArticle }) => {
  const {
    showVerificationStatus,
    showVerificationActions,
    showPrintArticleSheetActions,
    showDeleteArticleAction,
    shipment,
  } = useContext(ShipmentArticlesContext);
  const articleName = useArticleName({ article: shipmentArticle.article });

  const article = shipmentArticle.article;
  const showActions =
    showVerificationActions || showPrintArticleSheetActions || showDeleteArticleAction;

  return (
    <GridListItem textValue={articleName} style={{ marginBottom: '0.5rem' }}>
      <Card>
        <CardContent>
          <ArticleNameCell
            article={shipmentArticle.article}
            noLink
            badge={
              showVerificationStatus && (
                <ArticleReceivedVerificationStatusCell shipmentArticle={shipmentArticle} />
              )
            }
          />
        </CardContent>
        <CardItems>
          <ArticleRequestCardItem article={shipmentArticle.article} />
          <ArticleWeightCardItem article={shipmentArticle.article} />
        </CardItems>
        {!!shipmentArticle.issue && <ArticleIssueCell issue={shipmentArticle.issue} />}
        {showActions && (
          <CardActions>
            {showVerificationActions && (
              <ArticleReceivedVerificationActionCell
                shipmentArticle={shipmentArticle}
                shipment={shipment}
                key={`${shipmentArticle.verified}`}
              />
            )}
            {showDeleteArticleAction && (
              <ArticleDeleteArticleActionCell
                articleId={article.id}
                shipmentId={shipment.id}
                isDisabled={shipment.articles.length === 1}
              />
            )}
            {showPrintArticleSheetActions && (
              <ArticlePrintArticleSheetActionCell
                article={article}
                organization={shipment.organization}
              />
            )}
          </CardActions>
        )}
      </Card>
    </GridListItem>
  );
};
