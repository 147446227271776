import { Key } from 'react';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import { useUpdateArticle } from '@/models/article';
import { useColorOptions, useSizeOptions } from '@/models/product';
import { ArticleWithRelations, Request } from '@/models/request';

const ColorSize = ({ request, article }: { request: Request; article: ArticleWithRelations }) => {
  const { _ } = useLingui();

  const { mutate: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const handleSelectionChange = (property: 'size' | 'color', key: Key) => {
    updateArticle({
      data: {
        data: {
          ...article.data,
          [property]: key,
        },
      },
    });
  };

  const colorOptions = useColorOptions(article.product);
  const sizeOptions = useSizeOptions(article.product, article.productL1);

  return (
    <>
      <InputSelect
        variant="select"
        label={_(msg({ id: 'article.form.color.label', message: 'Color' }))}
        aria-label={_(msg({ id: 'article.form.color.label', message: 'Color' }))}
        placeholder={
          colorOptions.length > 0
            ? _(msg({ id: 'article.form.color.placeholder', message: 'Select the item color...' }))
            : 'N/A'
        }
        isDisabled={(!article.product && !article.productL1) || colorOptions.length === 0}
        isSearchable={false}
        options={colorOptions}
        getOptionValue={(colorOption) => colorOption.id}
        getOptionLabel={(colorOption) => colorOption.text}
        onChange={(colorOption) => {
          if (!colorOption) {
            return;
          }

          handleSelectionChange('color', colorOption.id);
        }}
        style={{ flex: 1 }}
      />

      <InputSelect
        variant="select"
        label={_(msg({ id: 'article.form.size.label', message: 'Size' }))}
        aria-label={_(msg({ id: 'article.form.size.label', message: 'Size' }))}
        placeholder={
          (!article.product && !article.productL1) || sizeOptions.length > 0
            ? _(msg({ id: 'article.form.size.placeholder', message: 'Select the item size...' }))
            : 'N/A'
        }
        isDisabled={sizeOptions.length === 0}
        isSearchable={false}
        options={sizeOptions}
        getOptionValue={(sizeOption) => sizeOption.id}
        getOptionLabel={(sizeOption) => sizeOption.text}
        onChange={(sizeOption) => {
          if (!sizeOption) {
            return;
          }

          handleSelectionChange('size', sizeOption.id);
        }}
        style={{ flex: 1 }}
      />
    </>
  );
};

export default ColorSize;
