import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ArticleStepper } from '@/components/ArticleStepper/ArticleStepper';
import Loader from '@/components/Loader';
import {
  PageLayout,
  PageLayoutCenter,
  PageLayoutContent,
  PageLayoutHeader,
  PageLayoutLeftPart,
  PageLayoutLeftPartContent,
  PageLayoutRightPart,
  PageLayoutTopPart,
} from '@/components/PageLayout';
import AlertBar from '@/design_system/AlertBar';
import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import { useArticleName } from '@/models/article';
import { useRequest } from '@/models/request';
import ArticleDispatch from '@/routes/Requests/components/ArticleDispatch';
import ActionTypes from '@/routes/Requests/Request/Article/ActionTypes';
import ClientComment from '@/routes/Requests/Request/Article/ClientComment';
import RequalificationComment from '@/routes/Requests/Request/Article/RequalificationComment';
import Warranty from '@/routes/Requests/Request/Article/Warranty';
import { ArticleActivities } from '@/routes/Requests/Request/components/Activities/ArticleActivities';
import { RequestBreadcrumb } from '@/routes/Requests/Request/components/RequestBreadcrumb/RequestBreadcrumb';
import { RequestComments } from '@/routes/Requests/Request/components/RequestComments';
import { useCurrentSession } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';
import { createBEMClasses } from '@/utils/classname';
import { useResetSimpleBlocker } from '@/utils/navigation';
import { refreshBrowserTabTitle } from '@/utils/refreshBrowserTabTitle';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

import ArticleActions from './components/ArticleActions';
import ArticleTaskOrStepMessage from './components/ArticleTaskOrStepMessage';
import { ArticleHeader } from './ArticleHeader';
import { ArticleInfoSidePanel, ArticleInfoTopPanel } from './ArticleInfo';
import { useArticleErrors } from './useArticleErrors';

import './Article.css';

const { block, element } = createBEMClasses('article');

export const Article = () => {
  const navigate = useNavigate();
  const { isMobile } = useViewPort();
  const { _ } = useLingui();
  const { requestId, articleId } = useParams();
  const { data: request, isLoading } = useRequest(requestId);
  const { currentSession } = useCurrentSession();
  const [commentsRef, scrollCommentsIntoView] = useScrollIntoView<HTMLDivElement>();
  const article = request?.allArticles.find(({ id }) => id === articleId);

  const articleName = useArticleName({ article });

  // Display the request reference in the browser tab title
  useEffect(() => {
    if (!request) {
      return;
    }

    const requestDetails = request.client?.name ?? request.store?.name;
    const requestReference = requestDetails
      ? `${request.reference} - ${articleName}`
      : request.reference;

    document.querySelector('meta[name="subtitle"]')?.setAttribute('content', requestReference);
    refreshBrowserTabTitle();

    return () => {
      document.querySelector('meta[name="subtitle"]')?.setAttribute('content', '');
      refreshBrowserTabTitle();
    };
  }, [request, articleName]);

  // Clear router blocker state after coming from requests/new
  useResetSimpleBlocker();

  const isOrganizationUser = currentSession?.organizations?.some(
    (organization) => organization.id === article?.organizationId
  );

  const [alertRef, scrollAlertIntoView] = useScrollIntoView<HTMLDivElement>();
  const [showErrors, setShowErrors] = useState(false);

  // 1st time the errors should be shown, the alert bar isn't visible yet that's why we need
  // this useEffect
  useEffect(() => {
    if (showErrors) {
      scrollAlertIntoView({
        block: 'center',
      });
    }
  }, [showErrors, scrollAlertIntoView]);

  const {
    productError,
    otherBrandError,
    actionsError,
    photosError,
    proofOfPurchaseError,
    workshopError,
  } = useArticleErrors(article, request);

  if (isLoading) {
    return (
      <PageLayoutCenter>
        <Loader style={{ height: '40px', width: '40px' }} />
        <p className="paragraph-100-regular">
          <Trans id="_general.loading">Loading...</Trans>
        </p>
      </PageLayoutCenter>
    );
  }

  if (!request || !article) {
    return (
      <PageLayoutCenter>
        <p className="paragraph-100-regular">
          <Trans id="article.unknown">Unknown article</Trans>
        </p>
      </PageLayoutCenter>
    );
  }

  const allowWarranty =
    article.steps?.find(
      (step) =>
        ['done', 'in-progress'].includes(step.status) &&
        !!step.config &&
        'allowWarranty' in step.config &&
        step.config.allowWarranty
    ) ?? false;

  const estimateArticleError =
    !!productError ||
    !!otherBrandError ||
    !!proofOfPurchaseError ||
    !!photosError ||
    !!actionsError ||
    !!workshopError
      ? _(
          msg({
            id: 'article.estimation.error',
            message: 'Please fill the missing information in order to complete the estimation',
          })
        )
      : undefined;

  const dispatchArticleError = workshopError
    ? _(
        msg({
          id: 'article.dispatch.error',
          message: 'Please select a workshop in order to save',
        })
      )
    : undefined;

  const acceptRequalificationError = actionsError
    ? _(
        msg({
          id: 'article.estimation.error',
          message: 'Please fill the missing information in order to complete the estimation',
        })
      )
    : undefined;

  const taskError =
    article.task?.type === 'estimate_article'
      ? estimateArticleError
      : article.task?.type === 'dispatch_article'
        ? dispatchArticleError
        : article.task?.type === 'accept_requalification'
          ? acceptRequalificationError
          : undefined;

  const checkErrors = () => {
    setShowErrors(true);

    if (taskError) {
      scrollAlertIntoView({
        block: 'center',
      });
    }

    return !!taskError;
  };

  return (
    <PageLayout className={block()}>
      <PageLayoutHeader>
        <ArticleHeader
          request={request}
          article={article}
          onCommentButtonPress={scrollCommentsIntoView}
        />
      </PageLayoutHeader>

      <PageLayoutContent>
        <PageLayoutLeftPart>
          <PageLayoutLeftPartContent>
            {isMobile && (
              <PageLayoutTopPart>
                <ErrorAlertBar alertRef={alertRef} error={showErrors ? taskError : undefined} />
                <ArticleInfoTopPanel article={article} request={request} showErrors={showErrors} />
              </PageLayoutTopPart>
            )}

            {!isMobile && (
              <>
                <ErrorAlertBar alertRef={alertRef} error={showErrors ? taskError : undefined} />
                <RequestBreadcrumb
                  request={request}
                  article={article}
                  onCommentButtonPress={scrollCommentsIntoView}
                />
              </>
            )}

            <Stack className={element('main')}>
              <Stack gap="1rem">
                <ArticleStepper
                  size={isMobile ? 'small' : 'large'}
                  showStepCount={isMobile}
                  article={article}
                  mode="step"
                  labelVariant={undefined}
                />
                <ArticleTaskOrStepMessage article={article} request={request} />
              </Stack>

              <Stack gap="2rem">
                {(article.task?.type === 'analyze_article' ||
                  article.task?.type === 'accept_requalification' ||
                  (article.step?.step === 'transit' &&
                    article.step.config.originType === 'workshop' &&
                    article.step.config.destinationType === 'workshop')) && (
                  <RequalificationComment article={article} />
                )}

                {!!request.client && allowWarranty && article.task?.type === 'estimate_article' && (
                  <Stack gap="0.5rem">
                    <h2 className="headline-400-bold">
                      <Trans id="article.warranty">Warranty</Trans>
                    </h2>
                    <Box className={element('warranty')}>
                      <Warranty
                        request={request}
                        article={article}
                        key={`purchase-date ${article.purchaseDate ?? ''}`}
                        error={showErrors ? { proofOfPurchaseError } : undefined}
                      />
                    </Box>
                  </Stack>
                )}

                <Stack gap="0.5rem">
                  <h2 className="headline-400-bold">
                    <Trans id="article.services">Services</Trans>
                  </h2>
                  <Box className={element('services')}>
                    <Stack gap="1rem">
                      <ActionTypes
                        request={request}
                        article={article}
                        mode="both"
                        isDisabled={article.task?.type !== 'estimate_article'}
                        isEditActionDisabled={
                          article.task?.type !== 'estimate_article' &&
                          article.task?.type !== 'accept_requalification'
                        }
                        isEditCustomActionWorkshopPriceDisabled={
                          article.task?.type !== 'estimate_article'
                        }
                        showWorkshopPrice
                        showOrganizationPrice={
                          request.requestorType !== 'store' && !currentSession?.workshop
                        }
                        showResponsibility
                        error={showErrors ? actionsError : undefined}
                      />

                      {!currentSession?.isResponsibleWorkshop(article) && (
                        <ArticleDispatch
                          request={request}
                          article={article}
                          disabled={
                            article.task?.type !== 'estimate_article' &&
                            article.task?.type !== 'dispatch_article'
                          }
                          error={showErrors ? workshopError : undefined}
                        />
                      )}
                    </Stack>
                  </Box>
                </Stack>

                {isOrganizationUser && request.requestorType !== 'store' && (
                  <Stack gap="0.5rem">
                    <h2 className="headline-400-bold">
                      <Trans id="article.client-communication">Communication to the client</Trans>
                    </h2>
                    <Box className={element('client-communication')}>
                      <ClientComment
                        request={request}
                        article={article}
                        disabled={article.task?.type !== 'estimate_article'}
                        key={`client-comment ${article.clientComment ?? ''}`}
                      />
                    </Box>
                  </Stack>
                )}

                <Stack gap={isMobile ? undefined : '0.5rem'} ref={commentsRef}>
                  <h2 className="headline-400-bold">
                    <Trans id="article.comment.title">Item comments</Trans>
                  </h2>
                  <ErrorBoundary>
                    <Box
                      className={element('comment', { 'no-background': isMobile })}
                      padding="0"
                      removeStyleOnMobile
                    >
                      <RequestComments requestId={request.id} articleId={article.id} />
                    </Box>
                  </ErrorBoundary>
                </Stack>

                <Stack gap={isMobile ? undefined : '0.5rem'} ref={null}>
                  <h2 className="headline-400-bold">
                    <Trans id="article.activity.title">Activity</Trans>
                  </h2>
                  <ErrorBoundary>
                    <Box
                      className={element('activity', { 'no-background': isMobile })}
                      padding="0"
                      removeStyleOnMobile
                    >
                      <ArticleActivities articleId={article.id} />
                    </Box>
                  </ErrorBoundary>
                </Stack>
              </Stack>
            </Stack>
          </PageLayoutLeftPartContent>

          <ArticleActions
            article={article}
            request={request}
            onActionDone={() => {
              navigate(`/requests/${request.id}`);
            }}
            onCheckErrors={checkErrors}
          />
        </PageLayoutLeftPart>

        {!isMobile && (
          <PageLayoutRightPart>
            <ArticleInfoSidePanel article={article} request={request} showErrors={showErrors} />
          </PageLayoutRightPart>
        )}
      </PageLayoutContent>
    </PageLayout>
  );
};

const ErrorAlertBar = ({
  alertRef,
  error,
}: {
  alertRef: React.RefObject<HTMLDivElement>;
  error?: string;
}) => {
  return (
    <div ref={alertRef} style={{ display: error ? 'block' : 'none' }}>
      <AlertBar size="large" type="error" title={error} />
    </div>
  );
};
