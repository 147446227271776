import { createContext } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import {
  RequestOrganizationPrice,
  RequestWorkshopPrice,
} from '@/components/ArticlesTableCells/ArticleMoneyCell';
import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import IconArchive from '@/icons/Archive.svg';
import IconClock from '@/icons/Clock.svg';
import IconTask from '@/icons/Task.svg';
import { RequestWithRelations } from '@/models/request';
import {
  ArchivedArticlesCardList,
  ArchivedArticlesTable,
  CompletedArticlesCardList,
  CompletedArticlesTable,
  OnGoingArticlesCardList,
} from '@/routes/Requests/components/ArticlesTable';
import { OnGoingArticlesTable } from '@/routes/Requests/components/ArticlesTable/OnGoingArticlesTable';
import { useCurrentSession } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import './RequestArticles.css';

const { block, element } = createBEMClasses('request-articles');

interface RequestArticlesContextData {
  request: RequestWithRelations;
  isWorkshop: boolean;
  workshopPriceLabel: string;
  organizationCostLabel: string;
  organizationPriceLabel: string;
}

export const RequestArticlesContext = createContext({} as RequestArticlesContextData);

export const RequestArticles = ({ request }: { request: RequestWithRelations }) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const { currentSession } = useCurrentSession();

  const articlesWithTaskToDo = request.articles.filter((article) =>
    article.task?.userRoles.some(({ permission, scope }) =>
      currentSession?.hasPermission(permission, scope)
    )
  );

  const completedArticles = request.articles.filter((article) => article.completed);

  const otherArticles = request.articles.filter(
    (article) => !articlesWithTaskToDo.includes(article) && !completedArticles.includes(article)
  );

  const workshopPriceLabel = _(
    msg({
      id: 'request.articles.table.column.workshop-price.label',
      message: 'Price',
    })
  );

  const organizationCostLabel = _(
    msg({
      id: 'request.articles.table.column.internal-cost.label',
      message: 'Internal cost',
    })
  );

  const organizationPriceLabel = _(
    msg({
      id: 'request.articles.table.column.client-price.label',
      message: 'Client price',
    })
  );

  const isWorkshop = !!currentSession?.workshop;
  const contextValue = {
    request,
    isWorkshop,
    workshopPriceLabel,
    organizationCostLabel,
    organizationPriceLabel,
  } satisfies RequestArticlesContextData;

  return (
    <RequestArticlesContext.Provider value={contextValue}>
      <Stack gap="1rem" className={block()}>
        {articlesWithTaskToDo.length > 0 && (
          <Box padding="0">
            <Stack>
              <Stack className={element('pending-tasks')} row>
                <IconTask />
                <span className="paragraph-100-medium">
                  <Trans id="request.articles.table.pending-tasks.label">Pending item tasks</Trans>
                </span>
              </Stack>
              {isMobile ? (
                <OnGoingArticlesCardList
                  articles={articlesWithTaskToDo}
                  mode="task"
                  label={_(
                    msg({
                      id: 'request.articles.table.pending-tasks.label',
                      message: 'Pending item tasks',
                    })
                  )}
                />
              ) : (
                <OnGoingArticlesTable articles={articlesWithTaskToDo} mode="task" />
              )}
            </Stack>
          </Box>
        )}
        {otherArticles.length > 0 && (
          <Box padding="0">
            <Stack>
              <Stack className={element('awaiting-tasks')} row>
                <IconClock />
                <span className="paragraph-100-medium">
                  <Trans id="request.articles.table.waiting-tasks.label">Waiting for others</Trans>
                </span>
              </Stack>
              {isMobile ? (
                <OnGoingArticlesCardList
                  articles={otherArticles}
                  mode="step"
                  label={_(
                    msg({
                      id: 'request.articles.table.waiting-tasks.label',
                      message: 'Waiting for others',
                    })
                  )}
                />
              ) : (
                <OnGoingArticlesTable articles={otherArticles} mode="step" />
              )}
            </Stack>
          </Box>
        )}
        {completedArticles.length > 0 && (
          <Box padding="0">
            <Stack>
              <Stack className={element('completed-articles')} row>
                <IconClock />
                <span className="paragraph-100-medium">
                  <Trans id="request.articles.table.completed.label">Completed items</Trans>
                </span>
              </Stack>
              {isMobile ? (
                <CompletedArticlesCardList articles={completedArticles} />
              ) : (
                <CompletedArticlesTable articles={completedArticles} />
              )}
            </Stack>
          </Box>
        )}
        {!isWorkshop && (
          <>
            {!!request.price && (
              <RequestOrganizationPrice price={request.price} label={_(organizationPriceLabel)} />
            )}
            <RequestWorkshopPrice price={request.cost} label={_(organizationCostLabel)} />
          </>
        )}
        {isWorkshop && <RequestWorkshopPrice price={request.price} label={_(workshopPriceLabel)} />}
        {request.archivedArticles.length > 0 && (
          <Box padding="0">
            <Stack>
              <Stack className={element('archived-articles')} row>
                <IconArchive />
                <span className="paragraph-100-medium">
                  <Trans id="request.articles.table.archived-articles.label">Archived items</Trans>
                </span>
              </Stack>
              {isMobile ? (
                <ArchivedArticlesCardList articles={request.archivedArticles} />
              ) : (
                <ArchivedArticlesTable articles={request.archivedArticles} />
              )}
            </Stack>
          </Box>
        )}
      </Stack>
    </RequestArticlesContext.Provider>
  );
};
