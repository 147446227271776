import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import Stack from '@/design_system/Stack';
import { useUpdateArticle } from '@/models/article';
import { useProductOptions } from '@/models/product';
import { type ArticleWithRelations, type RequestWithRelations } from '@/models/request';

export const ProductCategoryL2 = ({
  request,
  article,
  l2Error,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  l2Error?: string;
}) => {
  const { _ } = useLingui();

  const { mutate: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const { productL2Options } = useProductOptions(request.organization, article.productL1);

  return (
    <Stack gap="0.25rem">
      <InputSelect
        variant="select"
        label={_(msg({ id: 'article.form.category.label', message: 'Category' }))}
        placeholder={_(
          msg({
            id: 'article.form.category.placeholder',
            message: 'Select a category',
          })
        )}
        isDisabled={!!article.product || !article.productL1}
        value={
          productL2Options.find((productL2Option) => article.productL2 === productL2Option.id) ??
          null
        }
        isSearchable={false}
        options={productL2Options}
        getOptionValue={(productL2Option) => productL2Option.text}
        onChange={(productL2Option) => {
          if (productL2Option) {
            updateArticle({
              data: {
                productL2: productL2Option.id,
              },
            });
          }
        }}
        getOptionLabel={(productL2Option) => productL2Option.text}
        error={l2Error}
      />
    </Stack>
  );
};
