import { Fragment } from 'react';
import { msg, Plural, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ArticleNameCell } from '@/components/ArticlesTableCells';
import { ArticleDeleteArticleActionCell } from '@/components/ArticlesTableCells/ArticleDeleteArticleActionCell';
import ArticleIssueCell from '@/components/ArticlesTableCells/ArticleIssueCell';
import { ArticlePrintArticleSheetActionCell } from '@/components/ArticlesTableCells/ArticlePrintArticleSheetActionCell';
import ArticleReceivedVerificationActionCell from '@/components/ArticlesTableCells/ArticleReceivedVerificationActionCell';
import ArticleReceivedVerificationStatusCell from '@/components/ArticlesTableCells/ArticleReceivedVerificationStatusCell';
import ArticleRequestCell from '@/components/ArticlesTableCells/ArticleRequestCell/ArticleRequestCell';
import ArticleWeightCell from '@/components/ArticlesTableCells/ArticleWeightCell';
import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import Table from '@/design_system/Table';
import { Body, Cell, Column, Header, Row } from '@/design_system/Table/Table';
import { ShipmentWithRelations } from '@/models/shipment';
import { useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

const LegacyArticlesTable = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  const { currentSession } = useCurrentSession();

  const showVerificationActions =
    shipment.step === 'verification' && shipment.canReceptionBeVerifiedBy(currentSession);
  const showVerificationStatus = showVerificationActions || shipment.step === 'validated';
  const showPrintArticleSheetActions = shipment.step === 'preparation';
  const showDeleteArticleAction = shipment.step === 'preparation';

  if (isMobile) {
    return (
      <Box padding="16px" gap="1rem" className="bg-neutral-200">
        <p className="paragraph-100-medium">
          <Trans id="shipment.articles.table.total">
            Total{' '}
            <span className="paragraph-200-regular">
              (
              <Plural value={shipment.articles.length} one="# item" other="# items" />)
            </span>
          </Trans>
        </p>
        <Stack gap="0.75rem">
          {shipment.articles.map((shipmentArticle) => (
            <ArticleCard
              key={shipmentArticle.articleId}
              shipmentArticle={shipmentArticle}
              shipment={shipment}
              showVerificationStatus={showVerificationStatus}
              showVerificationActions={showVerificationActions}
              showPrintArticleSheetActions={showPrintArticleSheetActions}
              showDeleteArticleAction={showDeleteArticleAction}
            />
          ))}
        </Stack>
      </Box>
    );
  }

  return (
    <Table aria-label={_(msg({ id: 'shipment.articles.table.label', message: 'Items' }))}>
      <Header>
        <Column isRowHeader width="5fr" minWidth={150}>
          <Trans id="shipment.articles.table.articles.label">Items</Trans>
        </Column>
        {showVerificationStatus && (
          <Column minWidth={150}>
            <Trans id="shipment.articles.table.status.label">Status</Trans>
          </Column>
        )}
        <Column width="2fr" minWidth={200} align="start">
          <Trans id="shipment.articles.table.request.label">Request</Trans>
        </Column>
        <Column>
          <Trans id="shipment.articles.table.weight.label">Weight</Trans>
        </Column>
        {(showVerificationActions || showPrintArticleSheetActions || showDeleteArticleAction) && (
          <Column minWidth={100} />
        )}
      </Header>
      <Body>
        {shipment.articles.map((shipmentArticle) => (
          <Fragment key={shipmentArticle.articleId}>
            <ArticleRow
              shipmentArticle={shipmentArticle}
              shipment={shipment}
              showVerificationStatus={showVerificationStatus}
              showVerificationActions={showVerificationActions}
              showPrintArticleSheetActions={showPrintArticleSheetActions}
              showDeleteArticleAction={showDeleteArticleAction}
            />
            {shipmentArticle.issue && (
              <ArticleIssueRow
                issue={shipmentArticle.issue}
                span={3 + (showVerificationStatus ? 1 : 0) + (showVerificationActions ? 1 : 0)}
              />
            )}
          </Fragment>
        ))}
      </Body>
    </Table>
  );
};

const ArticleRow = ({
  shipmentArticle,
  shipment,
  showVerificationStatus,
  showVerificationActions,
  showPrintArticleSheetActions,
  showDeleteArticleAction,
}: {
  shipmentArticle: ShipmentWithRelations['articles'][number];
  shipment: ShipmentWithRelations;
  showVerificationStatus: boolean;
  showVerificationActions: boolean;
  showPrintArticleSheetActions: boolean;
  showDeleteArticleAction: boolean;
}) => {
  const article = shipmentArticle.article;

  return (
    <Row id={article.id} noBorder={!!shipmentArticle.issue}>
      <Cell>
        <ArticleNameCell article={article} />
      </Cell>
      {showVerificationStatus && (
        <Cell>
          <ArticleReceivedVerificationStatusCell shipmentArticle={shipmentArticle} />
        </Cell>
      )}
      <Cell align="start">
        <ArticleRequestCell article={article} organization={shipment.organization} />
      </Cell>
      <Cell>
        <ArticleWeightCell article={article} />
      </Cell>
      {showVerificationActions && (
        <Cell align="end">
          <ArticleReceivedVerificationActionCell
            shipmentArticle={shipmentArticle}
            shipment={shipment}
            key={`${shipmentArticle.verified}`}
          />
        </Cell>
      )}

      {(showPrintArticleSheetActions || showDeleteArticleAction) && (
        <Cell align="end">
          <Stack row gap="0.5rem">
            {showPrintArticleSheetActions && (
              <ArticlePrintArticleSheetActionCell
                article={article}
                organization={shipment.organization}
              />
            )}
            {showDeleteArticleAction && (
              <ArticleDeleteArticleActionCell
                articleId={article.id}
                shipmentId={shipment.id}
                isDisabled={shipment.articles.length === 1}
              />
            )}
          </Stack>
        </Cell>
      )}
    </Row>
  );
};

const ArticleCard = ({
  shipmentArticle,
  shipment,
  showVerificationStatus,
  showVerificationActions,
  showPrintArticleSheetActions,
  showDeleteArticleAction,
}: {
  shipmentArticle: ShipmentWithRelations['articles'][number];
  shipment: ShipmentWithRelations;
  showVerificationStatus: boolean;
  showVerificationActions: boolean;
  showPrintArticleSheetActions: boolean;
  showDeleteArticleAction: boolean;
}) => {
  const article = shipmentArticle.article;

  return (
    <Box padding="0">
      <Stack padding="16px" gap="1rem" className="bg-neutral-0">
        <Stack
          row
          gap="0.5rem"
          flexWrap="nowrap"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <ArticleNameCell article={article} />
          {showVerificationStatus && (
            <ArticleReceivedVerificationStatusCell shipmentArticle={shipmentArticle} />
          )}
        </Stack>
        <hr />
        <Stack gap="0.75rem">
          <ArticleRequestCell article={article} organization={shipment.organization} />
          <ArticleWeightCell article={article} showIcon />
        </Stack>
      </Stack>
      {showVerificationActions && (
        <Stack padding="12px 16px" className="bg-neutral-100">
          <ArticleReceivedVerificationActionCell
            shipmentArticle={shipmentArticle}
            shipment={shipment}
            key={`${shipmentArticle.verified}`}
          />
        </Stack>
      )}
      {(showPrintArticleSheetActions || showDeleteArticleAction) && (
        <Stack gap="0.5rem" padding="12px 16px" className="bg-neutral-100">
          {showPrintArticleSheetActions && (
            <ArticlePrintArticleSheetActionCell
              article={article}
              organization={shipment.organization}
            />
          )}
          {showDeleteArticleAction && (
            <ArticleDeleteArticleActionCell
              articleId={article.id}
              shipmentId={shipment.id}
              isDisabled={shipment.articles.length === 1}
            />
          )}
        </Stack>
      )}
      {!!shipmentArticle.issue && <ArticleIssueCell issue={shipmentArticle.issue} />}
    </Box>
  );
};

const ArticleIssueRow = ({ issue, span }: { issue: string; span: number }) => {
  return (
    <Row>
      <Cell colSpan={span} style={{ paddingTop: 0 }} align="stretch">
        <ArticleIssueCell issue={issue} />
      </Cell>
    </Row>
  );
};

export default LegacyArticlesTable;
