import { useState } from 'react';
import { msg, Plural, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { QRCodeSVG } from 'qrcode.react';

import config from '@/config';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconPlace from '@/icons/Place.svg';
import IconPrint from '@/icons/Print.svg';
import { BrandWrapper, BrandWrapperCenter, BrandWrapperFooter } from '@/layouts/Brand';
import { useArticleName } from '@/models/article';
import {
  ClientRequestWithRelations,
  useAcceptInitialQuote,
  useRefuseInitialQuote,
  useUpdateRequestClient,
} from '@/models/request';
import { useWorkflow } from '@/models/workflow';
import Refusal from '@/routes/Brand/Requests/Request/components/ClientRequestView/components/Refusal';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientInfo from '@/routes/Brand/Requests/Request/components/shared/ClientInfo';
import IconSuccess from '@/routes/Brand/Requests/Request/components/shared/IconSuccess';
import { PickupPointInfo } from '@/routes/Brand/Requests/Request/components/shared/PickupPointInfo/PickupPointInfo';
import { useClientToken, useCurrentOrganization } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';
import { formatCurrency } from '@/utils/number';

import './Validation.css';

const { block, element } = createBEMClasses('client-request-validation');

const Validation = ({
  request,
  onDownloadShippingLabel,
}: {
  request: ClientRequestWithRelations;
  onDownloadShippingLabel: () => void;
}) => {
  const {
    mutate: acceptInitialQuote,
    isPending: isPendingAcceptInitialQuote,
    isSuccess: isSuccessAcceptInitialQuote,
  } = useAcceptInitialQuote();

  const {
    mutate: refuseInitialQuote,
    isPending: isPendingRefuseInitialQuote,
    isSuccess: isSuccessRefuseInitialQuote,
  } = useRefuseInitialQuote();

  const [showRefuseView, setShowRefuseView] = useState(false);

  if (showRefuseView) {
    return (
      <BrandWrapper>
        <Refusal
          onCancelRefuse={() => setShowRefuseView(false)}
          isSuccess={isSuccessRefuseInitialQuote}
          isPending={isPendingRefuseInitialQuote}
          onRefuseRequest={(cancellationDetail) => {
            refuseInitialQuote({ id: request.id, cancellationDetail });
          }}
        />
      </BrandWrapper>
    );
  }

  const firstArticle = request.articles[0];
  const showShippingOptions =
    !!firstArticle.quoteAcceptedAt &&
    !!request.client &&
    request.client.shippingChoice === null &&
    !request.store;

  return (
    <BrandWrapper className={block()}>
      <BrandWrapperCenter>
        <Stack gap="1rem">
          {!showShippingOptions && <ValidationHeader request={request} />}
          {showShippingOptions && (
            <ShippingOrDropOffHeader
              request={request}
              onDownloadShippingLabel={onDownloadShippingLabel}
            />
          )}
          <PickupPointInfo request={request} />
          <ClientArticlesTable request={request} hideStepper showPrice showArticleComment />
          <ClientInfo request={request} />
        </Stack>
      </BrandWrapperCenter>
      {!showShippingOptions && (
        <BrandWrapperFooter>
          <Button variant="secondary-brand" size="large" onPress={() => setShowRefuseView(true)}>
            <Trans id="client.request.validation.entry.refuse">Refuse and cancel</Trans>
          </Button>
          <Button
            variant="brand"
            size="large"
            onPress={() => acceptInitialQuote({ id: request.id })}
            isLoading={isPendingAcceptInitialQuote || isSuccessAcceptInitialQuote}
          >
            <Trans id="client.request.validation.entry.accept">Accept and continue</Trans>
          </Button>
        </BrandWrapperFooter>
      )}
    </BrandWrapper>
  );
};

const ValidationHeader = ({ request }: { request: ClientRequestWithRelations }) => {
  const { _ } = useLingui();
  const firstArticleName = useArticleName({ article: request.articles[0], type: 'short' });

  return (
    <Stack gap="0.5rem" style={{ marginBottom: '1rem' }} alignItems="center">
      <h1 className="headline-200-bold headline-300-bold-mobile text-center">
        <Trans id="client.request.validation.entry.title">Care & Repair service estimate</Trans>
      </h1>
      <p className="paragraph-50-regular paragraph-100-regular-mobile text-center">
        <Trans id="client.request.validation.entry.description">
          Our experts have an estimate for{' '}
          <Plural
            value={request.articles.length}
            one={
              <span>
                your{' '}
                <span className="paragraph-50-medium paragraph-100-medium-mobile">
                  {firstArticleName}
                </span>
              </span>
            }
            other={
              <span>
                your{' '}
                <span className="paragraph-50-medium paragraph-100-medium-mobile"># items</span>
              </span>
            }
          />
        </Trans>
        :
      </p>
      <Stack row gap="0.5rem" alignItems="center">
        <p
          className="headline-100-bold headline-200-bold-mobile text-center"
          aria-label={_(
            msg({ id: 'client.request.validation.entry.price', message: 'Estimate price' })
          )}
        >
          {formatCurrency(request.clientCost?.amount, request.clientCost?.currency)}
        </p>
      </Stack>
    </Stack>
  );
};

const ShippingOrDropOffHeader = ({
  request,
  onDownloadShippingLabel,
}: {
  request: ClientRequestWithRelations;
  onDownloadShippingLabel: () => void;
}) => {
  const clientToken = useClientToken();
  const [organization] = useCurrentOrganization();
  const { data: workflow } = useWorkflow(request.workflowId);
  const allowDropOff = workflow?.config.shippingOptions.includes('store') ?? false;

  const { mutateAsync: updateRequestClient, isPending: isPendingRequestClient } =
    useUpdateRequestClient();

  return (
    <Stack gap="1rem">
      <Stack row gap="0.5rem" alignItems="center" flexWrap="nowrap">
        <div>
          <IconSuccess />
        </div>
        <p className="headline-200-bold headline-300-bold-mobile color-primary-800">
          <Trans id="client.request.validation.pending.title">
            Your care & repair service is confirmed
          </Trans>
        </p>
      </Stack>
      <Stack row gap="0.25rem">
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          <Trans id="client.request.validation.pending.shipment-options.title">
            It&apos;s time to ship us your{' '}
            <Plural value={request.articles.length} one="item" other="items" />!
          </Trans>
          {allowDropOff && (
            <>
              {' '}
              <Trans id="client.request.validation.pending.shipment-options.title2">
                You have 2 options:
              </Trans>
            </>
          )}
        </p>
      </Stack>
      <div className={element('shipment-options')}>
        <Stack gap="1rem">
          <Stack row gap="1rem" flexWrap="nowrap" alignItems="center">
            <img src="/shipment-label.png" alt="" />
            <Stack>
              <div className="paragraph-50-medium paragraph-100-medium-mobile">
                <Trans id="client.request.validation.pending.shipment-option.client.title">
                  Send it yourself
                </Trans>
              </div>
              <div className="paragraph-50-regular paragraph-100-regular-mobile">
                <Trans id="client.request.validation.pending.shipment-option.client.label">
                  Print the shipment label and stick it on the package, visible and affixed to a
                  flat surface.
                </Trans>
              </div>
            </Stack>
          </Stack>
          <Button
            variant="brand"
            size="large"
            disabled={isPendingRequestClient}
            onPress={() =>
              updateRequestClient({
                id: request.id,
                body: {
                  client: {
                    shippingChoice: 'home',
                  },
                },
              }).then(() => onDownloadShippingLabel())
            }
          >
            <IconPrint />
            <Trans id="client.request.validation.pending.shipment-option.client.action">
              Print shipping label
            </Trans>
          </Button>
        </Stack>
        {allowDropOff && (
          <>
            <span className="paragraph-50-regular">
              <Trans id="_general.or">or</Trans>
            </span>
            <Stack gap="1rem">
              <Stack row gap="1rem" flexWrap="nowrap" alignItems="center">
                <QRCodeSVG
                  className={element('shipment-options__qr-code')}
                  size={92}
                  value={`${config.appUrl}/requests/claim?id=${request.id}&clientToken=${clientToken}`}
                />
                <Stack>
                  <div className="paragraph-50-medium paragraph-100-medium-mobile">
                    <Trans id="client.request.validation.pending.shipment-option.store.title">
                      Bring it to a store
                    </Trans>
                  </div>
                  <div className="paragraph-50-regular paragraph-100-regular-mobile">
                    <Trans id="client.request.validation.pending.shipment-option.store.label">
                      Go to the nearest {organization?.name} store with this QR code to arrange
                      shipment.
                    </Trans>
                  </div>
                </Stack>
              </Stack>
              <Button
                variant="brand"
                size="large"
                href={organization?.config.storesUrl}
                target="_blank"
              >
                <IconPlace start />
                <Trans id="client.request.validation.pending.shipment-option.store.action">
                  Find a store
                </Trans>
              </Button>
            </Stack>
          </>
        )}
      </div>
    </Stack>
  );
};

export default Validation;
