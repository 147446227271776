import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import InputText from '@/design_system/InputText';
import Stack from '@/design_system/Stack';
import Table from '@/design_system/Table';
import { Body, Cell, Column, Header, Row } from '@/design_system/Table/Table';
import { TableViewSpacebarWorkaround } from '@/design_system/Table/TableViewSpacebarWorkaround';
import { useShowToast } from '@/design_system/Toast';
import IconCross from '@/icons/Cross.svg';
import { Role, useRoles } from '@/models/role';
import { useStores } from '@/models/store';
import { useInviteUser, useUpdateInvite } from '@/models/userRole';
import { useWorkshops } from '@/models/workshop';
import { useCurrentOrganization } from '@/services/auth';
import useDebouncedState from '@/utils/useDebouncedState';

/**
 * Used for both actual users and invited members which aren't users yet.
 */
export type UserWithRoles = {
  id?: string; // Only for existing users
  name?: string; // Only for existing users
  email: string;
  roles: UserRole[];
};

type UserRole = {
  id: string;
  roleId: string;
  role: Role;
  organizationId?: string | null;
  storeId?: string | null;
  store?: {
    name: string;
  } | null;
  workshopId?: string | null;
  workshop?: {
    name: string;
  } | null;
};

export const UserDialog = ({
  isOpen,
  setIsOpen,
  user,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  user?: UserWithRoles;
}) => {
  const { _ } = useLingui();
  const showToast = useShowToast();

  const [mutateError, setMutateError] = useState<string | null>(null);

  const { mutateAsync: inviteUser, isPending: isPendingInviteUser } = useInviteUser();
  const { mutateAsync: updateUserRole, isPending: isPendingUpdateUserRole } = useUpdateInvite();

  const isPending = isPendingInviteUser || isPendingUpdateUserRole;

  const submit = async ({
    userId,
    email,
    userRoles,
  }: {
    userId?: string;
    email?: string;
    userRoles: {
      id: string | null;
      roleId: string;
      organizationId: string | null;
      storeId: string | null;
      workshopId: string | null;
    }[];
  }) => {
    setMutateError(null);

    try {
      if (userId) {
        await updateUserRole({
          userId,
          userRoles,
        });

        setIsOpen(false);
      } else {
        await inviteUser({
          email: email!,
          userRoles,
        });

        showToast({
          text: _(
            msg({
              id: 'settings.users.invitation.success',
              message: `Invitation sent to ${email}`,
            })
          ),
          type: 'success',
        });

        setIsOpen(false);
      }
    } catch (error) {
      setMutateError(
        (error as Error).message ??
          _(
            msg({
              id: 'shipment.unknown-error',
              message: 'Unknown error. Please contact Support',
            })
          )
      );
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          if (isPending) return;
          setIsOpen(false);
        }
      }}
      title={
        user ? (
          <p className="sentry-mask">
            {_(
              msg({
                id: 'settings.users.update-modal.title',
                message: `Manage ${user.name ?? user.email}`,
              })
            )}
          </p>
        ) : (
          _(msg({ id: 'settings.users.invite-modal.title', message: 'Invite a user' }))
        )
      }
      style={{ width: '50rem' }}
    >
      <UserDialogForm
        user={user}
        onSubmit={submit}
        isPendingSubmit={isPending}
        cancel={() => setIsOpen(false)}
        mutateError={mutateError}
      />
    </Dialog>
  );
};

const UserDialogForm = ({
  user,
  onSubmit,
  isPendingSubmit,
  cancel,
  mutateError,
}: {
  user?: UserWithRoles;
  onSubmit: ({
    userId,
    email,
    userRoles,
  }: {
    userId?: string;
    email?: string;
    userRoles: {
      id: string | null;
      roleId: string;
      organizationId: string | null;
      storeId: string | null;
      workshopId: string | null;
    }[];
  }) => Promise<void>;
  isPendingSubmit: boolean;
  cancel: () => void;
  mutateError: string | null;
}) => {
  const { _ } = useLingui();

  const [showErrors, setShowErrors] = useState(false);

  const [email, setEmail] = useState(user?.email ?? '');
  const [userRoles, setUserRoles] = useState<UserRole[]>(user?.roles ?? []);

  const noEmail = !email
    ? _(
        msg({
          id: 'settings.users.invite-modal.error.no-email',
          message: 'Please enter a valid email',
        })
      )
    : undefined;
  const noRoles =
    userRoles.length === 0
      ? _(
          msg({
            id: 'settings.users.invite-modal.error.no-roles',
            message: 'At least one role is required',
          })
        )
      : undefined;
  const roleWithScopeError = userRoles.find((userRole) => {
    if (userRole.role.scopeStore && !userRole.storeId) return true;
    if (userRole.role.scopeWorkshop && !userRole.workshopId) return true;
    return false;
  })
    ? _(
        msg({
          id: 'settings.users.invite-modal.error.role-with-scope',
          message: 'Please assign an entity to all roles that requires it',
        })
      )
    : undefined;
  const hasStateError = noEmail || noRoles || roleWithScopeError;

  const handleSubmit = () => {
    if (hasStateError) {
      setShowErrors(true);
      return;
    }

    onSubmit({
      userId: user?.id ? user.id : undefined,
      email: user?.id ? undefined : email,
      userRoles: userRoles.map((userRole) => ({
        id: userRole.id.startsWith('new-') ? null : userRole.id,
        roleId: userRole.roleId,
        organizationId: userRole.organizationId ?? null,
        storeId: userRole.storeId ?? null,
        workshopId: userRole.workshopId ?? null,
      })),
    });
  };

  return (
    <>
      <main>
        <Stack gap="24px" style={{ minHeight: '20rem' }}>
          <InputText
            label={<Trans id="settings.users.invite-modal.field.email.label">Email</Trans>}
            type="email"
            placeholder="user@example.com"
            value={email}
            onChange={setEmail}
            isRequired
            isDisabled={isPendingSubmit || !!user}
            isInvalid={showErrors && !!noEmail}
            error={showErrors ? noEmail : undefined}
          />
          <UserRolesInput
            userRoles={userRoles}
            setUserRoles={setUserRoles}
            showErrors={showErrors}
          />
          {!user && (
            <p className="paragraph-100-regular">
              <Trans id="settings.users.invite-modal.disclaimer">
                The invited user will receive <b>an email with a link</b> to set up their account.
              </Trans>
            </p>
          )}
          {((showErrors && noRoles) || mutateError) && (
            <p className="paragraph-100-medium text-danger text-center">{mutateError ?? noRoles}</p>
          )}
        </Stack>
      </main>
      <footer>
        <Button
          variant="secondary"
          size="small"
          isLoading={isPendingSubmit}
          disabled={isPendingSubmit}
          onPress={cancel}
        >
          <Trans id="settings.users.invite-modal.cancel">Cancel</Trans>
        </Button>
        {user ? (
          <Button
            variant="primary"
            size="small"
            isLoading={isPendingSubmit}
            disabled={isPendingSubmit}
            onPress={handleSubmit}
          >
            <Trans id="settings.users.update-modal.submit">Save</Trans>
          </Button>
        ) : (
          <Button
            variant="primary"
            size="small"
            isLoading={isPendingSubmit}
            disabled={isPendingSubmit}
            onPress={handleSubmit}
          >
            <Trans id="settings.users.invite-modal.submit">Send invitation</Trans>
          </Button>
        )}
      </footer>
    </>
  );
};

const UserRolesInput = ({
  userRoles,
  setUserRoles,
  showErrors,
}: {
  userRoles: UserRole[];
  setUserRoles: (userRoles: UserRole[]) => void;
  showErrors: boolean;
}) => {
  const { _ } = useLingui();
  const [currentOrganization] = useCurrentOrganization();

  const { data: availableRoles } = useRoles();

  const options = (availableRoles ?? [])
    .map((role) => ({
      value: role.id,
      label: role.name,
      role,
      isMonoScope:
        (role.scopeOrganization ? 1 : 0) +
          (role.scopeStore ? 1 : 0) +
          (role.scopeWorkshop ? 1 : 0) ===
        1,
    }))
    .filter((role) => {
      // If mono-scope, can only be applied once
      if (role.isMonoScope) {
        return !userRoles.some((userRole) => userRole.roleId === role.value);
      }

      return true;
    });

  const showEntityColumn = !!currentOrganization;

  return (
    <Stack gap="0.5rem">
      <TableViewSpacebarWorkaround>
        <Table>
          <Header>
            <Column width="2fr" minWidth={200} isRowHeader>
              <Trans id="settings.users.invite-modal.column.roles">Roles</Trans>
            </Column>
            {showEntityColumn && (
              <Column width="2fr" minWidth={200}>
                <Trans id="settings.users.invite-modal.column.entity">Entity</Trans>
              </Column>
            )}
            <Column width={90} />
          </Header>
          <Body>
            {userRoles.map((userRole) => {
              const shouldShowStoreInput =
                userRole.role.scopeOrganization && userRole.role.scopeStore;
              const shouldShowWorkshopInput =
                userRole.role.scopeOrganization && userRole.role.scopeWorkshop;

              const setUserRole = (userRole: UserRole) =>
                setUserRoles(userRoles.map((ur) => (ur.id === userRole.id ? userRole : ur)));

              return (
                <Row key={userRole.id}>
                  <Cell>{userRole.role.name}</Cell>
                  {showEntityColumn && (
                    <Cell align="stretch">
                      {shouldShowStoreInput ? (
                        <StoreInput
                          userRole={userRole}
                          setUserRole={setUserRole}
                          userRoles={userRoles}
                          showErrors={showErrors}
                        />
                      ) : shouldShowWorkshopInput ? (
                        <WorkshopInput
                          userRole={userRole}
                          setUserRole={setUserRole}
                          userRoles={userRoles}
                          showErrors={showErrors}
                        />
                      ) : (
                        '-'
                      )}
                    </Cell>
                  )}
                  <Cell align="end">
                    <Button
                      variant="basic"
                      size="medium"
                      ariaLabel={_(
                        msg({
                          id: 'settings.users.invite-modal.remove-role',
                          message: 'Remove role',
                        })
                      )}
                      iconOnly
                      onPress={() => {
                        setUserRoles(userRoles.filter((ur) => ur.id !== userRole.id));
                      }}
                    >
                      <IconCross />
                    </Button>
                  </Cell>
                </Row>
              );
            })}
          </Body>
        </Table>
      </TableViewSpacebarWorkaround>
      <InputSelect
        options={options}
        aria-label={_(
          msg({ id: 'settings.users.invite-modal.select-role', message: 'Select a role' })
        )}
        placeholder={_(
          msg({ id: 'settings.users.invite-modal.select-role', message: 'Select a role' })
        )}
        getOptionValue={(option) => option?.value ?? ''}
        getOptionLabel={(option) => option?.label ?? ''}
        value={null}
        onChange={(selected) => {
          if (!selected) return;

          setUserRoles([
            ...userRoles,
            {
              id: `new-${Math.random()}`,
              roleId: selected.role.id,
              role: selected.role,
              organizationId: currentOrganization?.id,
            },
          ]);
        }}
      />
    </Stack>
  );
};

const StoreInput = ({
  userRole,
  setUserRole,
  userRoles,
  showErrors,
}: {
  userRole: UserRole;
  setUserRole: (userRole: UserRole) => void;
  userRoles: UserRole[];
  showErrors: boolean;
}) => {
  const { _ } = useLingui();
  const [searchStore, debouncedSearchStore, setSearchStore] = useDebouncedState('', 300);
  const { data: { stores } = { stores: [] }, isFetching: isFetchingStores } = useStores({
    internal: true,
    search: debouncedSearchStore,
  });

  const otherUserRoleWithSameRole = userRoles.filter(
    (ur) => ur.roleId === userRole.roleId && ur.id !== userRole.id
  );

  const options = stores
    .filter((store) => !otherUserRoleWithSameRole.some((ur) => ur.storeId === store.id))
    .map((store) => ({
      value: store.id,
      label: store.name,
    }));

  return (
    <InputSelect
      aria-label={_(
        msg({ id: 'settings.users.invite-modal.select-store', message: 'Select a store' })
      )}
      placeholder={_(
        msg({ id: 'settings.users.invite-modal.select-store', message: 'Select a store' })
      )}
      filterOption={null}
      inputValue={searchStore}
      onInputChange={setSearchStore}
      isLoading={isFetchingStores}
      value={
        userRole.storeId
          ? {
              value: userRole.storeId,
              label: userRole.store!.name,
            }
          : null
      }
      onChange={(selected) => {
        if (!selected) return;

        setUserRole({
          ...userRole,
          storeId: selected.value,
          store: {
            name: selected.label,
          },
        });
      }}
      options={options}
      error={
        showErrors && !userRole.storeId
          ? _(
              msg({
                id: 'settings.users.invite-modal.error.no-store',
                message: 'Store is required',
              })
            )
          : undefined
      }
    />
  );
};

const WorkshopInput = ({
  userRole,
  setUserRole,
  userRoles,
  showErrors,
}: {
  userRole: UserRole;
  setUserRole: (userRole: UserRole) => void;
  userRoles: UserRole[];
  showErrors: boolean;
}) => {
  const { _ } = useLingui();
  const [searchWorkshop, debouncedSearchWorkshop, setSearchWorkshop] = useDebouncedState('', 300);
  const { data: { workshops } = { workshops: [] }, isFetching: isFetchingWorkshops } = useWorkshops(
    {
      limit: 100,
      internal: true,
      search: debouncedSearchWorkshop,
    }
  );

  const otherUserRoleWithSameRole = userRoles.filter(
    (ur) => ur.roleId === userRole.roleId && ur.id !== userRole.id
  );

  const options = workshops
    .filter((workshop) => !otherUserRoleWithSameRole.some((ur) => ur.workshopId === workshop.id))
    .map((workshop) => ({
      value: workshop.id,
      label: workshop.name,
    }));

  return (
    <InputSelect
      aria-label={_(
        msg({ id: 'settings.users.invite-modal.select-workshop', message: 'Select a workshop' })
      )}
      placeholder={_(
        msg({ id: 'settings.users.invite-modal.select-workshop', message: 'Select a workshop' })
      )}
      filterOption={null}
      inputValue={searchWorkshop}
      onInputChange={setSearchWorkshop}
      isLoading={isFetchingWorkshops}
      value={
        userRole.workshopId
          ? {
              value: userRole.workshopId,
              label: userRole.workshop!.name,
            }
          : null
      }
      onChange={(selected) => {
        if (!selected) return;

        setUserRole({
          ...userRole,
          workshopId: selected.value,
          workshop: {
            name: selected.label,
          },
        });
      }}
      options={options}
      error={
        showErrors && !userRole.workshopId
          ? _(
              msg({
                id: 'settings.users.invite-modal.error.no-workshop',
                message: 'Workshop is required',
              })
            )
          : undefined
      }
    />
  );
};
