import { useMemo } from 'react';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import cn from 'classnames';

import { ArticleDisplayedStep, ArticleTask } from '@/api';
import { UserCard } from '@/components/UserCard/UserCard';
import Badge from '@/design_system/Badge';
import Box from '@/design_system/Box';
import Chip from '@/design_system/Chip';
import { InputMultiSelect } from '@/design_system/InputMultiSelect/InputMultiSelect';
import LegacyInputMultiSelect, {
  BasicMultiSelectItem,
  RawMultiSelectItem,
} from '@/design_system/InputMultiSelect/LegacyInputMultiSelect';
import Stack from '@/design_system/Stack';
import { Cell } from '@/design_system/Table/Table';
import IconCloth from '@/icons/Cloth.svg';
import IconStore from '@/icons/Store.svg';
import IconStoreClient from '@/icons/StoreClient.svg';
import IconTask from '@/icons/Task.svg';
import IconUser from '@/icons/User.svg';
import { ARTICLE_DISPLAYED_STEPS, ARTICLE_TASK_TYPES } from '@/models/article';
import { useOrganizationWorkflows } from '@/models/organization';
import { REQUESTOR_TYPES, RequestorType, RequestWithRelations } from '@/models/request';
import { getRequestorTypeLabel } from '@/models/request';
import { UserWithRelations } from '@/models/user';
import { useCurrentOrganization, useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

export const REQUESTS_PER_PAGE = 10;

export const requestorTypeIcons = {
  client: <IconUser />,
  store: <IconStore />,
  'client-via-store': <IconStoreClient />,
};

export const RequestReference = ({ request }: { request: RequestWithRelations }) => {
  const { isMobile } = useViewPort();
  const { _ } = useLingui();

  return (
    <Stack
      gap={isMobile ? '0.5rem' : '0.125rem'}
      row={isMobile}
      alignItems={isMobile ? 'center' : 'flex-start'}
    >
      <div className={isMobile ? 'paragraph-200-medium text-secondary' : 'paragraph-100-regular'}>
        {request.reference}
      </div>
      {request.isInDraftStep && (
        <Badge color="black" size="x-small" variant="low">
          {_(msg({ id: 'article.status.draft', message: 'Draft' }))}
        </Badge>
      )}
    </Stack>
  );
};

export const RequestNameCell = ({ request }: { request: RequestWithRelations }) => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();

  if (currentSession?.workshop?.external) {
    return (
      <Cell>
        <Stack row gap="0.5rem" alignItems="center" flexWrap="nowrap">
          <img
            src={request.organization.logoSquare ?? request.organization.logo}
            alt=""
            style={{ width: 40, height: 40 }}
          />
          <div>
            <p className="paragraph-100-regular">{request.organization.name}</p>
            <p className="paragraph-200-regular">
              {_(getRequestorTypeLabel(request.requestorType))}
            </p>
          </div>
        </Stack>
      </Cell>
    );
  }

  return (
    <Cell icon={requestorTypeIcons[request.requestorType]} className="sentry-mask">
      <p className="paragraph-100-regular">{request.name.major}</p>
      {request.name.minor && <p className="paragraph-200-regular">{request.name.minor}</p>}
    </Cell>
  );
};

export const RequestTasks = ({ request }: { request: RequestWithRelations }) => {
  const { isMobile } = useViewPort();
  const { _ } = useLingui();

  const tasks = useMemo(() => {
    const tasksCount = request.articles.reduce<Partial<Record<ArticleTask['type'], number>>>(
      (acc, article) => {
        const taskType = article.task?.type;

        if (taskType) {
          if (acc[taskType]) {
            acc[taskType]++;
          } else {
            acc[taskType] = 1;
          }
        }

        return acc;
      },
      {}
    );

    return ARTICLE_TASK_TYPES.filter((task) => !!tasksCount[task.id]).map((task) => ({
      id: task.id,
      label: `${_(task.label)}${tasksCount[task.id]! > 1 ? ` (x${tasksCount[task.id]})` : ''}`,
      labelVariant: task.labelVariant,
    }));
  }, [_, request.articles]);

  if (isMobile) {
    return (
      <>
        {tasks.map((task) => (
          <Box padding="0.25rem 0.5rem" style={{ flex: 'none' }} key={task.id}>
            <Stack
              row
              gap="0.25rem"
              alignItems="center"
              className={cn('paragraph-200-regular', {
                'text-danger': task.labelVariant === 'danger',
              })}
            >
              <IconTask style={{ fontSize: '1rem' }} />
              {task.label}
            </Stack>
          </Box>
        ))}
      </>
    );
  }

  return (
    <ul style={tasks.length <= 1 ? { listStyle: 'none', padding: 0 } : undefined}>
      {!tasks.length && <li>-</li>}
      {!!tasks.length &&
        tasks.map((task) => (
          <li
            key={task.id}
            className={cn({
              'text-danger': task.labelVariant === 'danger',
            })}
          >
            {task.label}
          </li>
        ))}
    </ul>
  );
};

export const RequestNameCardItem = ({ request }: { request: RequestWithRelations }) => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();

  if (currentSession?.workshop?.external) {
    return (
      <Stack style={{ alignItems: 'baseline' }}>
        <p className="paragraph-100-medium text-ellipsis">{request.organization.name}</p>
        <p className="paragraph-200-regular text-secondary">
          {_(getRequestorTypeLabel(request.requestorType))}
        </p>
      </Stack>
    );
  }

  return (
    <Stack style={{ alignItems: 'baseline' }}>
      <p className="paragraph-100-medium text-ellipsis">{request.name.major}</p>
      {request.name.minor && (
        <p className="paragraph-200-regular text-secondary">{request.name.minor}</p>
      )}
    </Stack>
  );
};

export const ArticlesCardItem = ({ request }: { request: RequestWithRelations }) => (
  <Box padding="0.25rem 0.5rem" style={{ flex: 'none' }}>
    <Stack row gap="0.25rem" alignItems="center" className="paragraph-200-medium">
      <IconCloth style={{ fontSize: '16px' }} />
      <span>{request.allArticles.length}</span>
    </Stack>
  </Box>
);

export const RequestorTypeSelect = ({
  selectedKeys,
  onSelectionChange,
}: {
  selectedKeys: RequestorType[];
  onSelectionChange: (keys: RequestorType[]) => void;
}) => {
  const { _ } = useLingui();

  const requestorTypeOptions = REQUESTOR_TYPES.map((requestorType) => ({
    ...requestorType,
    icon: requestorTypeIcons[requestorType.id],
  }));

  return (
    <div style={{ minWidth: '175px' }}>
      <InputMultiSelect
        aria-label={_(
          msg({ id: 'requests.filter.requestor.label', message: 'Search by applicant' })
        )}
        variant="select"
        placeholder={_(
          msg({ id: 'requests.filter.requestor.placeholder', message: 'Applicant type: All' })
        )}
        value={requestorTypeOptions.filter((requestorTypeOption) =>
          selectedKeys.includes(requestorTypeOption.id)
        )}
        options={requestorTypeOptions}
        getOptionValue={(requestorType) => _(getRequestorTypeLabel(requestorType.id))}
        getOptionLabel={(requestorType) => _(getRequestorTypeLabel(requestorType.id))}
        onChange={(requestorTypes) => {
          const requestorTypesIds = requestorTypes.map(({ id }) => id);
          onSelectionChange([...requestorTypesIds]);
        }}
        onRemoveValue={(requestorTypeId) => {
          onSelectionChange(selectedKeys.filter((selectedKey) => selectedKey !== requestorTypeId));
        }}
      />
    </div>
  );
};

export const DisplayedStepSelect = ({
  selectedKeys,
  onSelectionChange,
}: {
  selectedKeys: ArticleDisplayedStep[];
  onSelectionChange: (keys: ArticleDisplayedStep[]) => void;
}) => {
  const { _ } = useLingui();

  const { currentSession } = useCurrentSession();
  const [organization] = useCurrentOrganization();
  const { data: workflows } = useOrganizationWorkflows(organization?.slug);

  const availableSteps = ARTICLE_DISPLAYED_STEPS.filter((displayedStep) => {
    const organizationWorkflowsHaveStep = workflows?.length
      ? workflows.some((workflow) => workflow.displayedSteps.includes(displayedStep.id))
      : true;

    // TO DO: Improve configuration of which steps the workshop can see
    const canWorkshopSeeStep = currentSession?.workshop ? displayedStep.id !== 'estimation' : true;

    return organizationWorkflowsHaveStep && canWorkshopSeeStep;
  });

  const selectedSteps = ARTICLE_DISPLAYED_STEPS.filter((step) => selectedKeys.includes(step.id));

  return (
    <LegacyInputMultiSelect
      placeholder={_(msg({ id: 'requests.filter.step.placeholder', message: 'Step: All' }))}
      ariaLabel={_(msg({ id: 'requests.filter.step.label', message: 'Search by step' }))}
      size="large"
      style={{ minWidth: 175 }}
      onSelectionChange={(keys) => {
        onSelectionChange([...keys] as ArticleDisplayedStep[]);
      }}
      selectedKeys={selectedKeys}
      chips={selectedSteps.map((step) => (
        <Chip
          key={step.id}
          size="x-small"
          onCancel={() => {
            onSelectionChange(selectedKeys.filter((id) => id !== step.id));
          }}
        >
          {_(step.name)}
        </Chip>
      ))}
    >
      {availableSteps.map((step) => (
        <BasicMultiSelectItem key={step.id} id={step.id} text={_(step.name)} />
      ))}
    </LegacyInputMultiSelect>
  );
};

export const TaskSelect = ({
  selectedKeys,
  onSelectionChange,
}: {
  selectedKeys: ArticleTask['type'][];
  onSelectionChange: (keys: ArticleTask['type'][]) => void;
}) => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();

  const availableTasks = ARTICLE_TASK_TYPES.filter(
    (task) =>
      !!task.permissions.find((taskPermission) =>
        currentSession?.roles.some((role) => role.role.permissions.includes(taskPermission))
      )
  );

  const selectedTasks = ARTICLE_TASK_TYPES.filter((task) => selectedKeys.includes(task.id));

  return (
    <LegacyInputMultiSelect
      placeholder={_(msg({ id: 'requests.filter.task.placeholder', message: 'Task: All' }))}
      ariaLabel={_(msg({ id: 'requests.filter.task.label', message: 'Search by task' }))}
      size="large"
      style={{ minWidth: 175 }}
      onSelectionChange={(keys) => {
        onSelectionChange([...keys] as ArticleTask['type'][]);
      }}
      selectedKeys={selectedKeys}
      chips={selectedTasks.map((task) => {
        return (
          <Chip
            key={task.id}
            size="x-small"
            onCancel={() => {
              onSelectionChange(selectedKeys.filter((s) => s !== task.id));
            }}
          >
            {_(task.label)}
          </Chip>
        );
      })}
    >
      {availableTasks.map((task) => (
        <BasicMultiSelectItem key={task.id} id={task.id} text={_(task.label)} />
      ))}
    </LegacyInputMultiSelect>
  );
};

export const CollaboratorSelect = ({
  selectedKeys,
  onSelectionChange,
  users,
}: {
  selectedKeys: string[];
  onSelectionChange: (keys: string[]) => void;
  users?: UserWithRelations[];
}) => {
  const { _ } = useLingui();

  return (
    <LegacyInputMultiSelect
      placeholder={_(
        msg({ id: 'requests.filter.collaborator.placeholder', message: 'Collaborators: All' })
      )}
      ariaLabel={_(
        msg({ id: 'requests.filter.collaborator.label', message: 'Search by collaborators' })
      )}
      size="large"
      style={{ minWidth: 185 }}
      onSelectionChange={(keys) => {
        onSelectionChange([...keys] as string[]);
      }}
      selectedKeys={selectedKeys}
      chips={users
        ?.filter((user) => selectedKeys.includes(user.id))
        .map((user) => (
          <Chip
            size="x-small"
            key={user.id}
            onCancel={() => {
              onSelectionChange(selectedKeys.filter((c) => c !== user.id));
            }}
          >
            {user.name}
          </Chip>
        ))}
    >
      {users?.map((user) => <UserMultiSelectItem key={user.id} user={user} />)}
    </LegacyInputMultiSelect>
  );
};

export const UserMultiSelectItem = ({ user }: { user: UserWithRelations }) => {
  return (
    <RawMultiSelectItem
      id={user.id}
      textValue={user.name}
      value={{ selectedNode: <UserCard user={user} size="small" /> }}
    >
      <UserCard user={user} size="small" />
    </RawMultiSelectItem>
  );
};
