import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Chip from '@/design_system/Chip';
import LegacyInputMultiSelect from '@/design_system/InputMultiSelect';
import { BasicMultiSelectItem } from '@/design_system/InputMultiSelect/LegacyInputMultiSelect';
import { SHIPMENT_CARRIERS, ShipmentCarrier } from '@/models/shipment';

export const CarrierSelect = ({
  selectedKeys,
  onSelectionChange,
}: {
  selectedKeys: ShipmentCarrier[];
  onSelectionChange: (keys: ShipmentCarrier[]) => void;
}) => {
  const { _ } = useLingui();

  return (
    <LegacyInputMultiSelect
      placeholder={_(msg({ id: 'shipments.filter.carrier.placeholder', message: 'Carrier: All' }))}
      ariaLabel={_(msg({ id: 'shipments.filter.carrier.label', message: 'Search by carrier' }))}
      size="large"
      style={{ minWidth: 175 }}
      onSelectionChange={(keys) => {
        onSelectionChange([...keys] as ShipmentCarrier[]);
      }}
      selectedKeys={selectedKeys}
      chips={SHIPMENT_CARRIERS.filter((carrier) => selectedKeys.includes(carrier.id)).map(
        (carrier) => (
          <Chip
            size="x-small"
            key={carrier.id}
            onCancel={() => {
              onSelectionChange(selectedKeys.filter((c) => c !== carrier.id));
            }}
          >
            {carrier.name}
          </Chip>
        )
      )}
    >
      {SHIPMENT_CARRIERS.map((carrier) => (
        <BasicMultiSelectItem id={carrier.id} key={carrier.id} text={carrier.name} />
      ))}
    </LegacyInputMultiSelect>
  );
};
