import { Endpoints } from '@/api';

export type Activity =
  | Endpoints['GET /requests/:id/activities']['response']['activities'][number]
  | Endpoints['GET /shipments/:id/activities']['response']['activities'][number]
  | Endpoints['GET /articles/:id/activities']['response']['activities'][number];

export type ActivityOfType<T extends Activity['type']> = Extract<Activity, { type: T }>;

export const STACKABLE_ACTIVITY_TYPES = [
  'job_sent_for_acceptation',
  'job_auto_accepted',
  'job_accepted',
  'job_refused',
  'article_payment_choice',
] as const;

type StackableActivityType = (typeof STACKABLE_ACTIVITY_TYPES)[number];

type StackableActivity = ActivityOfType<StackableActivityType>;

export const groupByDate = (activities: Activity[]): Record<string, Activity[]> => {
  return activities.reduce(
    (acc, activity) => {
      const date = new Date(activity.createdAt).toDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(activity);
      return acc;
    },
    {} as Record<string, Activity[]>
  );
};

export const stackSimilarActivities = (activities: Activity[]): Activity[][] => {
  return activities.reduce(
    (acc, activity) => {
      const lastGroup = acc[acc.length - 1];
      const lastActivity = lastGroup.at(-1);
      if (!lastActivity) {
        lastGroup.push(activity);
      } else if (
        (STACKABLE_ACTIVITY_TYPES as readonly string[]).includes(activity.type) &&
        areSimilarActivities(activity as StackableActivity, lastActivity)
      ) {
        lastGroup.push(activity);
      } else {
        acc.push([activity]);
      }
      return acc;
    },
    [[]] as Activity[][]
  );
};

const areSimilarActivities = (a: StackableActivity, b: Activity): boolean => {
  if (a.type !== b.type) {
    return false;
  }

  // If activities have more than a 5-min difference, they are not similar
  if (Math.abs(new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()) > 5 * 60 * 1000) {
    return false;
  }

  const type = a.type;

  if (
    (type === 'job_sent_for_acceptation' ||
      type === 'job_accepted' ||
      type === 'job_auto_accepted' ||
      type === 'job_refused') &&
    'workshop' in b &&
    a.workshop.name === b.workshop.name
  ) {
    return true;
  }

  if (type === 'article_payment_choice' && 'value' in b.data && a.data.value === b.data.value) {
    return true;
  }

  return false;
};
