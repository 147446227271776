import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Dialog from '@/design_system/Dialog';
import Stack from '@/design_system/Stack';
import { type ArticleWithRelations, type RequestWithRelations } from '@/models/request';
import { ArticlePhotoUpload } from '@/routes/Requests/New/components/ArticlePhotoUpload';
import { useCurrentSession } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';

import {
  ProductCategoryL1,
  ProductCategoryL2,
  ProductCategoryL3,
} from './components/ProductCategories';
import ColorSize from './ColorSize';
import { Brand } from './components';
import ProductInfo from './ProductInfo';
import { useArticleErrors } from './useArticleErrors';

export const ArticleInformationFormModal = ({
  request,
  article,
  isOpen,
  closeModal,
  showErrors,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  isOpen: boolean;
  closeModal: () => void;
  showErrors?: boolean;
}) => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();
  const { productError, productL2Error, otherBrandError } = useArticleErrors(article, request);

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={closeModal}
      title={_(msg({ id: 'article.edit.title', message: 'Edit information' }))}
    >
      <main>
        <ErrorBoundary>
          <Stack gap="1rem">
            <ArticlePhotoUpload article={article} />

            {!currentSession?.workshop?.external && (
              <ProductInfo
                request={request}
                article={article}
                row={false}
                key={article.productId}
                showReference
                error={showErrors && !!productError ? productError : undefined}
              />
            )}

            <ProductCategoryL1
              request={request}
              article={article}
              l1Error={showErrors && !!productError ? productError : undefined}
            />

            <ProductCategoryL2
              request={request}
              article={article}
              l2Error={showErrors && !!productL2Error ? productL2Error : undefined}
            />

            {request.organization.brands.length && (
              <Brand request={request} article={article} otherBrandError={otherBrandError} />
            )}

            <ProductCategoryL3 request={request} article={article} />

            <ColorSize request={request} article={article} />
          </Stack>
        </ErrorBoundary>
      </main>
    </Dialog>
  );
};
