/**
 * Wrapper around react-spectrum's TableView component to work around a bug
 * with handling of the Space key:
 * https://github.com/adobe/react-spectrum/issues/1214
 * The bug is caused by code in
 * https://github.com/adobe/react-spectrum/blob/main/packages/@react-aria/selection/src/useTypeSelect.ts#L92
 * that implements "type-ahead" functionality. This workaround
 * effectively disables that functionality, in a pretty hacky way.
 */
export function TableViewSpacebarWorkaround({ children }: { children: React.ReactNode }) {
  return (
    <div
      onKeyDownCapture={(event) => {
        // When we capture a keydown event for the space key,
        // replace the key property with another value so that react-spectrum
        // does not trigger its special logic for the space key
        // (assuming we don't really need that, anyway).
        // The new value is a non-breakable space, which is similar to
        // the original space, just in case any other code relies on this event.
        if (event.key === ' ') {
          event.key = '\u00a0';
        }
      }}
    >
      {children}
    </div>
  );
}
