import { type GroupBase } from 'react-select';

import { InputSelect, InputSelectProps } from '@/design_system/InputSelect/InputSelect';

import './LegacyInputMultiSelect.css';

export const InputMultiSelect = <Option, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: InputSelectProps<Option, true, Group>
) => {
  return (
    <InputSelect
      {...props}
      isMulti
      isSearchable={false}
      isClearable={false}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
    />
  );
};
