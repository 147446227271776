import { createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { CreationStepConfig } from '@/api';
import {
  RequestOrganizationPrice,
  RequestWorkshopPrice,
} from '@/components/ArticlesTableCells/ArticleMoneyCell';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button/Button';
import Stack from '@/design_system/Stack';
import IconAdd from '@/icons/Add.svg';
import { RequestWithRelations, useCreateDraftRequestArticle } from '@/models/request';
import {
  CreationArticlesCardList,
  CreationArticlesTable,
} from '@/routes/Requests/components/ArticlesTable';
import useViewPort from '@/utils/useViewport';

export interface NewRequestArticlesContextData {
  request: RequestWithRelations;
  showWorkshop?: boolean;
  showOrganizationPrice?: boolean;
  showWorkshopPrice?: boolean;
  costLabel: string;
  priceLabel: string;
}

export const NewRequestArticlesContext = createContext({} as NewRequestArticlesContextData);

export const NewRequestArticles = ({ request }: { request: RequestWithRelations }) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const hasArticles = request.articles.length > 0;
  const navigate = useNavigate();

  const {
    mutateAsync: createArticle,
    isPending: isPendingCreateArticle,
    isSuccess: isSuccessCreateArticle,
    reset: resetCreateArticle,
  } = useCreateDraftRequestArticle(request.id);

  const handleCreateArticle = async () => {
    const { id } = await createArticle();

    navigate(`/requests/new/${request.id}/articles/${id}`, {
      state: { from: 'request', isNew: true, skipRouterBlocker: true },
      replace: true,
    });
    resetCreateArticle();
  };

  const creationConfig = request.articles[0]?.step?.config as
    | CreationStepConfig['config']
    | undefined;

  const showWorkshop = creationConfig?.requireDispatch;
  const showWorkshopPrice = creationConfig?.requireCost;
  const showOrganizationPrice =
    creationConfig?.requirePrice === true && request.requestorType !== 'store';

  const costLabel = _(
    msg({
      id: 'request.articles.table.column.internal-cost.label',
      message: 'Internal cost',
    })
  );

  const priceLabel = _(
    msg({ id: 'request.articles.table.column.client-price.label', message: 'Client price' })
  );

  const contextValue = {
    request,
    showWorkshop,
    showOrganizationPrice,
    showWorkshopPrice,
    costLabel,
    priceLabel,
  } satisfies NewRequestArticlesContextData;

  return (
    <NewRequestArticlesContext.Provider value={contextValue}>
      <Stack gap="1rem">
        <Box padding="0" removeStyleOnMobile>
          {hasArticles && (
            <Stack>
              {isMobile ? (
                <CreationArticlesCardList articles={request.articles} />
              ) : (
                <div style={{ borderBottom: '1px solid var(--color-neutral-300)' }}>
                  <CreationArticlesTable articles={request.articles} />
                </div>
              )}
            </Stack>
          )}

          <Stack
            padding={isMobile ? undefined : '1rem'}
            alignItems={isMobile ? 'stretch' : 'flex-start'}
            gap={isMobile ? '0.5rem' : '1rem'}
          >
            {!hasArticles && (
              <p className="paragraph-100-regular paragraph-200-regular-mobile text-secondary">
                <Trans id="requests.new.articles.no-article">No items added yet</Trans>
              </p>
            )}
            {request.draft && (
              <Button
                style={{ flex: isMobile ? 1 : undefined }}
                variant="secondary"
                size="medium"
                onPress={handleCreateArticle}
                isLoading={isPendingCreateArticle || isSuccessCreateArticle}
                dataTrackingId="requests.new.articles.add"
              >
                <IconAdd />
                <Trans id="requests.new.articles.add">Add an item</Trans>
              </Button>
            )}
          </Stack>
        </Box>
        {hasArticles && (
          <>
            {showOrganizationPrice && (
              <RequestOrganizationPrice price={request.price} label={priceLabel} />
            )}
            {showWorkshopPrice && <RequestWorkshopPrice price={request.cost} label={costLabel} />}
          </>
        )}
      </Stack>
    </NewRequestArticlesContext.Provider>
  );
};
