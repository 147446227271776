import { useContext } from 'react';
import { GridList, GridListItem } from 'react-aria-components';
import { useNavigate } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import {
  ArticleCostCardItem,
  ArticleNameCell,
  ArticlePriceCardItem,
  ArticleWorkshopCell,
  BaseMoneyCell,
} from '@/components/ArticlesTableCells';
import {
  ArticleServiceTypeCardItem,
  ArticleServiceTypeCell,
} from '@/components/ArticlesTableCells/ArticleServiceTypeCell';
import { ArticleWorkshopCardItem } from '@/components/ArticlesTableCells/ArticleWorkshopCell';
import { Card, CardActions, CardContent, CardItems } from '@/components/Card/Card';
import Badge from '@/design_system/Badge';
import Button from '@/design_system/Button/Button';
import Stack from '@/design_system/Stack';
import Table from '@/design_system/Table';
import { Body, Cell, Column, Header, Row } from '@/design_system/Table/Table';
import IconCancel from '@/icons/Cancel.svg';
import IconEdit from '@/icons/Edit.svg';
import { useArticleName } from '@/models/article';
import { ArticleWithRelations, useDeleteDraftRequestArticle } from '@/models/request';
import {
  useArticleDetailsError,
  useArticleServicesError,
} from '@/routes/Requests/New/components/ArticleForm';
import { NewRequestArticlesContext } from '@/routes/Requests/New/components/NewRequestArticles';
import useViewPort from '@/utils/useViewport';

export const CreationArticlesTable = ({ articles }: { articles: ArticleWithRelations[] }) => {
  const { _ } = useLingui();

  return (
    <Table
      aria-label={_(msg({ id: 'request.articles.table.label', message: 'Items' }))}
      extraSidePadding
    >
      <CreationArticlesTableHeader />
      <Body>
        {articles.map((article) => (
          <CreationArticlesTableRow key={article.id} article={article} />
        ))}
      </Body>
    </Table>
  );
};

const CreationArticlesTableHeader = () => {
  const { showWorkshop, costLabel, showOrganizationPrice, priceLabel, showWorkshopPrice } =
    useContext(NewRequestArticlesContext);

  return (
    <Header>
      <Column isRowHeader width="1fr" minWidth={200}>
        <Trans id="request.articles.table.label">Items</Trans>
      </Column>
      <Column width="1fr" minWidth={128}>
        <Trans id="request.articles.table.column.service-type.label">Service Type</Trans>
      </Column>
      {showWorkshop && (
        <Column width="1fr" minWidth={160}>
          <Trans id="request.articles.table.column.workshop.label">Workshop</Trans>
        </Column>
      )}
      {!showWorkshop && showWorkshopPrice && (
        <Column width="0.5fr" minWidth={100} align="end">
          {costLabel}
        </Column>
      )}
      {showOrganizationPrice && (
        <Column width="0.5fr" minWidth={100} align="end">
          {priceLabel}
        </Column>
      )}
      <Column width={100} align="end"></Column>
    </Header>
  );
};

const CreationArticlesTableRow = ({ article }: { article: ArticleWithRelations }) => {
  const { showWorkshop, showOrganizationPrice, showWorkshopPrice, request } =
    useContext(NewRequestArticlesContext);
  const navigate = useNavigate();

  return (
    <Row id={article.id}>
      <Cell>
        <ArticleNameCell article={article} badge={<DraftBadge article={article} />} noLink />
      </Cell>
      <Cell>
        <ArticleServiceTypeCell article={article} />
      </Cell>
      {showWorkshop && (
        <Cell>
          <ArticleWorkshopCell article={article} showWorkshopPrice />
        </Cell>
      )}
      {!showWorkshop && showWorkshopPrice && (
        <Cell align="end">
          <BaseMoneyCell price={article.cost} />
        </Cell>
      )}
      {showOrganizationPrice && (
        <Cell align="end">
          <BaseMoneyCell price={article.price} />
        </Cell>
      )}
      <Cell align="end">
        <Stack row gap="0.5rem">
          <EditButton
            onEdit={() =>
              navigate(`/requests/new/${request.id}/articles/${article.id}`, {
                state: { from: 'request', isNew: false, skipRouterBlocker: true },
                replace: true,
              })
            }
          />
          {request.draft && <DeleteButton article={article} />}
        </Stack>
      </Cell>
    </Row>
  );
};

export const CreationArticlesCardList = ({ articles }: { articles: ArticleWithRelations[] }) => {
  const { _ } = useLingui();
  return (
    <GridList
      aria-label={_(
        msg({
          id: 'requests.new.articles.label',
          message: 'Items',
        })
      )}
    >
      {articles.map((article) => (
        <CreationArticleCard key={article.id} article={article} />
      ))}
    </GridList>
  );
};

const CreationArticleCard = ({ article }: { article: ArticleWithRelations }) => {
  const navigate = useNavigate();
  const { showWorkshop, showOrganizationPrice, showWorkshopPrice, costLabel, priceLabel, request } =
    useContext(NewRequestArticlesContext);

  const articleName = useArticleName({ article });

  return (
    <GridListItem id={article.id} textValue={articleName} style={{ marginBottom: '0.5rem' }}>
      <Card>
        <CardContent>
          <ArticleNameCell
            id={article.id}
            article={article}
            badge={<DraftBadge article={article} />}
            noLink
          />
          <CardItems>
            {article.hasActions && <ArticleServiceTypeCardItem article={article} />}
            {showWorkshop && <ArticleWorkshopCardItem article={article} />}
            {showWorkshopPrice && <ArticleCostCardItem article={article} costLabel={costLabel} />}
            {showOrganizationPrice && (
              <ArticlePriceCardItem article={article} priceLabel={priceLabel} />
            )}
          </CardItems>
        </CardContent>
        <CardActions>
          {request.draft && <DeleteButton article={article} />}
          <EditButton
            onEdit={() =>
              navigate(`/requests/new/${request.id}/articles/${article.id}`, {
                state: { from: 'request', isNew: false, skipRouterBlocker: true },
                replace: true,
              })
            }
          />
        </CardActions>
      </Card>
    </GridListItem>
  );
};

const EditButton = ({ onEdit }: { onEdit: () => void }) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  if (isMobile) {
    return (
      <Button style={{ flex: 1 }} variant="secondary" size="medium" onPress={onEdit}>
        <IconEdit />
        <Trans id="request.articles.table.column.actions.edit">Edit</Trans>
      </Button>
    );
  }

  return (
    <Button
      iconOnly
      variant="secondary"
      size="medium"
      tooltip={_(msg({ id: 'request.articles.table.column.actions.edit', message: 'Edit' }))}
      ariaLabel={_(msg({ id: 'request.articles.table.column.actions.edit', message: 'Edit' }))}
      onPress={onEdit}
    >
      <IconEdit />
    </Button>
  );
};

const DeleteButton = ({ article }: { article: ArticleWithRelations }) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  const {
    mutateAsync: deleteArticle,
    isPending: isPendingDeleteArticle,
    isSuccess: isSuccessDeleteArticle,
  } = useDeleteDraftRequestArticle(article.requestId);

  if (isMobile) {
    return (
      <Button
        style={{ flex: 1 }}
        variant="secondary"
        size="medium"
        onPress={() => {
          deleteArticle(article.id);
        }}
        isLoading={isPendingDeleteArticle || isSuccessDeleteArticle}
      >
        <IconCancel />
        <Trans id="request.articles.table.column.actions.remove">Remove</Trans>
      </Button>
    );
  }

  return (
    <Button
      iconOnly
      variant="secondary"
      size="medium"
      tooltip={_(msg({ id: 'request.articles.table.column.actions.remove', message: 'Remove' }))}
      ariaLabel={_(msg({ id: 'request.articles.table.column.actions.remove', message: 'Remove' }))}
      onPress={() => {
        deleteArticle(article.id);
      }}
      isLoading={isPendingDeleteArticle || isSuccessDeleteArticle}
    >
      <IconCancel />
    </Button>
  );
};

const DraftBadge = ({ article }: { article: ArticleWithRelations }) => {
  const articleDetailsError = useArticleDetailsError(article);
  const articleServicesError = useArticleServicesError(article);

  const isDraft = articleDetailsError.hasError || articleServicesError.hasError;

  if (!isDraft) {
    return null;
  }

  return (
    <Badge color="black" variant="low">
      <Trans id="request.articles.table.status.draft">Draft</Trans>
    </Badge>
  );
};
