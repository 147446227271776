import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import RadioButtonGroup from '@/design_system/RadioButtonGroup';
import RadioGroup from '@/design_system/RadioGroup';
import Stack from '@/design_system/Stack';
import { Cell, Row } from '@/design_system/Table/Table';
import TextArea from '@/design_system/TextArea';
import { ARTICLE_CANCELLATION_REASONS, ArticleCancellationDetail } from '@/models/article';

export type Choice = {
  value: 'accepted' | 'refused' | null;
  reason: ArticleCancellationDetail['reason'] | null;
  otherReason: string | null;
};

export type Choices = Record<string, Choice>;

const options = [
  {
    children: <Trans id="article-choice-cell.option.accept">Accept</Trans>,
    value: 'accepted',
  },
  {
    children: <Trans id="article-choice-cell.option.refuse">Refuse</Trans>,
    value: 'refused',
  },
];

export const ArticleChoiceCell = ({
  choice,
  setChoice,
  showChoicesError,
}: {
  choice: Choice;
  setChoice: (choice: Choice) => void;
  showChoicesError?: boolean;
}) => {
  const { _ } = useLingui();

  return (
    <div>
      <RadioGroup
        align="right"
        size="small"
        aria-label={_(msg({ id: 'article-choice-cell.label', message: 'Choice' }))}
        value={choice.value}
        onChange={(value) => setChoice({ ...choice, value: value as 'accepted' | 'refused' })}
        options={options}
        isInvalid={showChoicesError && !choice.value}
      />
    </div>
  );
};

const ChoiceRefusedInputs = ({
  choice,
  setChoice,
  showChoicesError,
}: {
  choice: Choice;
  setChoice: (choice: Choice) => void;
  showChoicesError?: boolean;
}) => {
  const { _ } = useLingui();

  const missingReason = showChoicesError && !choice.reason;
  const missingOtherReason = showChoicesError && choice.reason === 'other' && !choice.otherReason;

  return (
    <Stack gap="0.5rem">
      <InputSelect
        aria-label={_(
          msg({
            id: 'client.request.refusal.reason.label',
            message: 'Refusal reason',
          })
        )}
        placeholder={_(
          msg({
            id: 'client.request.refusal.reason.placeholder',
            message: 'Select a reason...',
          })
        )}
        options={ARTICLE_CANCELLATION_REASONS.map(({ id }) => ({ id }))}
        variant="select"
        value={choice.reason ? { id: choice.reason } : null}
        isSearchable={false}
        onChange={(reason) => setChoice({ ...choice, reason: reason?.id ?? null })}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) =>
          _(ARTICLE_CANCELLATION_REASONS.find(({ id }) => id === option.id)!.label)
        }
      />
      {choice.reason === 'other' && (
        <>
          <TextArea
            ariaLabel={_(
              msg({
                id: 'client.request.refusal.other.placeholder',
                message: 'Specify the reason...',
              })
            )}
            placeholder={_(
              msg({
                id: 'client.request.refusal.other.placeholder',
                message: 'Specify the reason...',
              })
            )}
            value={choice.otherReason ?? undefined}
            onChange={(evt) => setChoice({ ...choice, otherReason: evt.target.value })}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            isInvalid={missingOtherReason}
            // Because the TextArea is in a Table, we need to use a native textarea to avoid
            // the focus trap issue when trying to add characters in the middle of a sentence.
            isNativeTextarea
          />
        </>
      )}
      <p className="text-danger paragraph-100-regular">
        {missingReason ? (
          <Trans id="article-choice-cell.error.missing-reason">Please select a reason</Trans>
        ) : missingOtherReason ? (
          <Trans id="article-choice-cell.error.missing-other-reason">
            Please specify the reason
          </Trans>
        ) : null}
      </p>
    </Stack>
  );
};

export const ChoiceRefusedRow = ({
  choice,
  setChoice,
  showChoicesError,
  span,
}: {
  choice: Choice;
  setChoice: (choice: Choice) => void;
  showChoicesError?: boolean;
  span: number;
}) => {
  return (
    <Row>
      <Cell colSpan={span} style={{ paddingTop: 0 }} align="stretch">
        <ChoiceRefusedInputs
          choice={choice}
          setChoice={setChoice}
          showChoicesError={showChoicesError}
        />
      </Cell>
    </Row>
  );
};

export const ArticleChoiceSection = ({
  choice,
  setChoice,
  showChoicesError,
}: {
  choice: Choice;
  setChoice: (choice: Choice) => void;
  showChoicesError?: boolean;
}) => {
  const { _ } = useLingui();

  return (
    <Stack gap="0.75rem">
      <RadioButtonGroup
        variant="brand"
        ariaLabel={_(msg({ id: 'article-choice-cell.label', message: 'Choice' }))}
        value={choice.value}
        onChange={(value) => setChoice({ ...choice, value: value as 'accepted' | 'refused' })}
        options={options}
      />
      {choice.value === 'refused' && (
        <ChoiceRefusedInputs
          choice={choice}
          setChoice={setChoice}
          showChoicesError={showChoicesError}
        />
      )}
    </Stack>
  );
};
