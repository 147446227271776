import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import { useUpdateArticle } from '@/models/article';
import { useProductOptions } from '@/models/product';
import { type ArticleWithRelations, type RequestWithRelations } from '@/models/request';

export const ProductCategoryL3 = ({
  request,
  article,
  l3Error,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  l3Error?: string;
}) => {
  const { _ } = useLingui();

  const { mutate: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const { productL3Options } = useProductOptions(request.organization, article.productL1);

  return (
    <InputSelect
      variant="select"
      label={_(msg({ id: 'article.form.material.label', message: 'Material' }))}
      aria-label={_(msg({ id: 'article.form.material.label', message: 'Material' }))}
      placeholder={_(
        msg({
          id: 'article.form.material.placeholder',
          message: 'Select a material',
        })
      )}
      isDisabled={!article.productL1 || productL3Options.length === 0}
      isSearchable={false}
      options={productL3Options}
      getOptionValue={(productL3Option) => productL3Option.id}
      getOptionLabel={(productL3Option) => productL3Option.text}
      onChange={(productL3Option) => {
        if (productL3Option) {
          updateArticle({
            data: {
              productL3: productL3Option.id,
            },
          });
        }
      }}
      error={l3Error}
      style={{ flex: 1 }}
    />
  );
};
