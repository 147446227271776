import { useCallback, useState } from 'react';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { PriceAggregate, ProductL1, ProductL2, ProductL3 } from '@/api';
import Button from '@/design_system/Button';
import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import IconAddToList from '@/icons/AddToList.svg';
import {
  ActionTypeOrganizationArticle,
  PackActionTypeOrganizationArticle,
  useActionTypes,
} from '@/models/actionType';
import { ArticleAction, ArticleCustomAction, ArticlePackAction } from '@/models/request';
import { useDatabaseTranslation } from '@/models/translation';
import { useCurrentSession } from '@/services/auth';
import { Currency } from '@/utils/number';
import useDebouncedState from '@/utils/useDebouncedState';

import { ActionDropdownItem } from './ActionDropdownItem';
import ActionsTable from './ActionsTable';
import CustomActionDialog from './CustomActionDialog';
import { PackActionDropdownItem } from './PackActionDropdownItem';

const ActionTypeSearchSelect = ({
  mode,
  showWorkshopPrice,
  showOrganizationPrice,
  showResponsibility,
  allowCustomActions,
  articleActions,
  articleCustomActions,
  articlePackActions,
  onChangeActions,
  onChangeCustomActions,
  onChangePackActions,
  oldArticleActions,
  oldArticleCustomActions,
  oldArticlePackActions,
  isDisabled = false,
  isEditActionDisabled = false,
  isEditCustomActionWorkshopPriceDisabled = false,
  requestId,
  customActionWorkshopPriceCurrency,
  customActionOrganizationPriceCurrency,
  productL1,
  productL2,
  productL3,
  error,
  isRequalification = false,
}: {
  mode: 'need' | 'action' | 'both';
  showWorkshopPrice?: boolean;
  showOrganizationPrice?: boolean;
  showResponsibility?: boolean;
  allowCustomActions?: boolean;
  articleActions: ArticleAction[];
  articleCustomActions: ArticleCustomAction[];
  articlePackActions: ArticlePackAction[];
  onChangeActions: (actions: ArticleAction[]) => void;
  onChangeCustomActions: (customActions: ArticleCustomAction[]) => void;
  onChangePackActions: (packActions: ArticlePackAction[]) => void;
  oldArticleActions?: ArticleAction[];
  oldArticleCustomActions?: ArticleCustomAction[];
  oldArticlePackActions?: ArticlePackAction[];
  isDisabled?: boolean;
  isEditActionDisabled?: boolean;
  isEditCustomActionWorkshopPriceDisabled?: boolean;
  requestId: string;
  customActionWorkshopPriceCurrency?: Currency;
  customActionOrganizationPriceCurrency?: Currency;
  productL1?: ProductL1;
  productL2?: ProductL2;
  productL3?: ProductL3;
  error?: string;
  /**
   * This field is temporary until we refactor the requalification to directly update in DB
   * instead of using the frontend state.
   */
  isRequalification?: boolean;
}) => {
  const { _ } = useLingui();
  const { _db } = useDatabaseTranslation();
  const { currentSession } = useCurrentSession();

  const isWorkshop = !!currentSession?.workshop;

  const [query, debouncedQuery, setQuery] = useDebouncedState<string>('', 500);

  const [isOpenCustomActionDialog, setIsOpenCustomActionDialog] = useState(false);
  const [customActionToEdit, setCustomActionToEdit] = useState<ArticleCustomAction>();

  const actionTypeQueryMode = mode === 'action' ? 'name' : mode === 'need' ? 'needName' : 'both';

  const {
    data: { actionTypes, packActionTypes } = { actionTypes: [], packActionTypes: [] },
    isFetching,
  } = useActionTypes(
    {
      requestId,
      query: debouncedQuery || undefined,
      queryMode: debouncedQuery ? actionTypeQueryMode : undefined,
      productL1,
      productL2,
      productL3,
    },
    {
      enabled: !isDisabled,
      keepPreviousData: true,
    }
  );

  const handleSelectionChange = (id: string) => {
    if (id) {
      const actionAlreadyAdded =
        !!articleActions.find((action) => action.actionTypeOrganizationId === id) ||
        !!articlePackActions.find((packAction) => packAction.packActionTypeOrganizationId === id);

      if (!actionAlreadyAdded) {
        const newAction = actionTypes?.find(
          (actionTypeOrganization) => actionTypeOrganization.id === id
        );
        const newPackAction = packActionTypes?.find(
          (packActionTypeOrganization) => packActionTypeOrganization.id === id
        );

        if (newAction) {
          const workshopPrice = {
            amount: newAction.dynamicCost?.amount ?? 0,
            currency: newAction.dynamicCost?.currency ?? 'EUR',
            details: [],
          } as unknown as PriceAggregate;

          onChangeActions([
            ...articleActions,
            new ActionTypeOrganizationArticle({
              quantity: 1,
              id: crypto.randomUUID(),
              actionTypeOrganizationId: newAction.id,
            })
              .with('actionTypeOrganization', newAction)
              // Front-end only action price to be displayed on ActionsTable in the requalification dialog
              .with('price', isWorkshop ? workshopPrice : undefined)
              .with('cost', undefined),
          ]);
        } else if (newPackAction) {
          const workshopPrice = {
            amount: newPackAction.dynamicCost?.amount ?? 0,
            currency: newPackAction.dynamicCost?.currency ?? 'EUR',
            details: [],
          } as unknown as PriceAggregate;

          onChangePackActions([
            ...articlePackActions,
            new PackActionTypeOrganizationArticle({
              quantity: 1,
              id: crypto.randomUUID(),
              packActionTypeOrganizationId: newPackAction.id,
            })
              .with('packActionTypeOrganization', newPackAction)
              // Front-end only action price to be displayed on ActionsTable in the requalification dialog
              .with('price', isWorkshop ? workshopPrice : undefined)
              .with('cost', undefined),
          ]);
        }
      }

      setQuery('');
    }
  };

  const handleDecrementAction = (actionId: string) => {
    const newActions = articleActions
      .map((action) =>
        action.id === actionId ? Object.assign(action, { quantity: action.quantity - 1 }) : action
      )
      .filter(({ quantity }) => quantity > 0);

    onChangeActions(newActions);
  };

  const handleIncrementAction = (actionId: string) => {
    const newActions = articleActions.map((action) =>
      action.id === actionId ? Object.assign(action, { quantity: action.quantity + 1 }) : action
    );

    onChangeActions(newActions);
  };

  const handleDecrementPackAction = (packActionId: string) => {
    const newPackActions = articlePackActions
      .map((packAction) =>
        packAction.id === packActionId
          ? Object.assign(packAction, { quantity: packAction.quantity - 1 })
          : packAction
      )
      .filter(({ quantity }) => quantity > 0);

    onChangePackActions(newPackActions);
  };

  const handleIncrementPackAction = (packActionId: string) => {
    const newPackActions = articlePackActions.map((packAction) =>
      packAction.id === packActionId
        ? Object.assign(packAction, { quantity: packAction.quantity + 1 })
        : packAction
    );

    onChangePackActions(newPackActions);
  };

  const handleCloseCustomActionDialog = () => {
    setIsOpenCustomActionDialog(false);
    setCustomActionToEdit(undefined);
  };

  const handleAddCustomAction = (newCustomAction: ArticleCustomAction) => {
    onChangeCustomActions([...articleCustomActions, newCustomAction]);
    setIsOpenCustomActionDialog(false);
  };

  const handleStartEditCustomAction = (customAction: ArticleCustomAction) => {
    setIsOpenCustomActionDialog(true);
    setCustomActionToEdit(customAction);
  };

  const handleEditCustomAction = (newCustomAction: ArticleCustomAction) => {
    const newCustomActions = articleCustomActions.map((customAction) =>
      customAction.id === customActionToEdit?.id ? newCustomAction : customAction
    );

    onChangeCustomActions(newCustomActions);
    setCustomActionToEdit(undefined);
    setIsOpenCustomActionDialog(false);
  };

  const handleDecrementCustomAction = (customActionId: string) => {
    const newCustomActions = articleCustomActions
      .map((customAction) =>
        customAction.id === customActionId
          ? Object.assign(customAction, { quantity: customAction.quantity - 1 })
          : customAction
      )
      .filter(({ quantity }) => quantity > 0);

    onChangeCustomActions(newCustomActions);
  };

  const handleIncrementCustomAction = (customActionId: string) => {
    const newCustomActions = articleCustomActions.map((customAction) =>
      customAction.id === customActionId
        ? Object.assign(customAction, { quantity: customAction.quantity + 1 })
        : customAction
    );

    onChangeCustomActions(newCustomActions);
  };

  const handleChangeActionResponsibility = (actionId: string, brandResponsibility: boolean) => {
    const newActions = articleActions.map((action) =>
      action.id === actionId ? Object.assign(action, { brandResponsibility }) : action
    );

    onChangeActions(newActions);
  };

  const handleChangePackActionResponsibility = (
    packActionId: string,
    brandResponsibility: boolean
  ) => {
    const newPackActions = articlePackActions.map((packAction) =>
      packAction.id === packActionId
        ? Object.assign(packAction, { brandResponsibility })
        : packAction
    );

    onChangePackActions(newPackActions);
  };

  const handleChangeCustomActionResponsibility = (
    customActionId: string,
    brandResponsibility: boolean
  ) => {
    const newCustomActions = articleCustomActions.map((customAction) =>
      customAction.id === customActionId
        ? Object.assign(customAction, { brandResponsibility })
        : customAction
    );

    onChangeCustomActions(newCustomActions);
  };

  const handleUploadDefectPhoto = useCallback(
    (actionId: string, defectPhotoId: string) => {
      if (articleActions.find((action) => action.id === actionId)) {
        const newActions = articleActions.map((action) =>
          action.id === actionId
            ? Object.assign(action, {
                defectPhotoIds: [...(action.defectPhotoIds ?? []), defectPhotoId],
              })
            : action
        );

        return onChangeActions(newActions);
      }

      if (articlePackActions.find((packAction) => packAction.id === actionId)) {
        const newPackActions = articlePackActions.map((packAction) =>
          packAction.id === actionId
            ? Object.assign(packAction, {
                defectPhotoIds: [...(packAction.defectPhotoIds ?? []), defectPhotoId],
              })
            : packAction
        );

        return onChangePackActions(newPackActions);
      }

      if (articleCustomActions.find((customAction) => customAction.id === actionId)) {
        const newCustomActions = articleCustomActions.map((customAction) =>
          customAction.id === actionId
            ? Object.assign(customAction, {
                defectPhotoIds: [...(customAction.defectPhotoIds ?? []), defectPhotoId],
              })
            : customAction
        );

        return onChangeCustomActions(newCustomActions);
      }
    },
    [
      articleActions,
      articlePackActions,
      articleCustomActions,
      onChangeActions,
      onChangePackActions,
      onChangeCustomActions,
    ]
  );

  const handleDeleteDefectPhoto = useCallback(
    (actionId: string, defectPhotoId: string) => {
      if (articleActions.find((action) => action.id === actionId)) {
        const newArticles = articleActions.map((action) =>
          action.id === actionId
            ? Object.assign(action, {
                defectPhotoIds: (action.defectPhotoIds ?? []).filter((id) => id !== defectPhotoId),
              })
            : action
        );

        return onChangeActions(newArticles);
      }

      if (articlePackActions.find((packAction) => packAction.id === actionId)) {
        const newPackActions = articlePackActions.map((packAction) =>
          packAction.id === actionId
            ? Object.assign(packAction, {
                defectPhotoIds: (packAction.defectPhotoIds ?? []).filter(
                  (id) => id !== defectPhotoId
                ),
              })
            : packAction
        );

        return onChangePackActions(newPackActions);
      }

      if (articleCustomActions.find((customAction) => customAction.id === actionId)) {
        const newCustomActions = articleCustomActions.map((customAction) =>
          customAction.id === actionId
            ? Object.assign(customAction, {
                defectPhotoIds: (customAction.defectPhotoIds ?? []).filter(
                  (id) => id !== defectPhotoId
                ),
              })
            : customAction
        );

        return onChangeCustomActions(newCustomActions);
      }
    },
    [
      articleActions,
      articlePackActions,
      articleCustomActions,
      onChangeActions,
      onChangePackActions,
      onChangeCustomActions,
    ]
  );

  const label = _(
    mode === 'need'
      ? msg({ id: 'article.form.actions.label.defect', message: 'Defects' })
      : msg({ id: 'article.form.actions.label', message: 'Care & repair actions' })
  );

  const customActionDialogTriggerLabel = _(
    msg({ id: 'article.form.custom-action.dialog.trigger.label', message: 'Add a new action' })
  );

  const actionTypeOptions = [
    ...(packActionTypes || []).filter(
      (packAction) =>
        !articlePackActions.find(
          (selectedPackAction) =>
            packAction.id === selectedPackAction.packActionTypeOrganizationId &&
            selectedPackAction.quantity > 0
        )
    ),
    ...(actionTypes || []).filter(
      (action) =>
        !articleActions.find(
          (selectedAction) =>
            action.id === selectedAction.actionTypeOrganizationId && selectedAction.quantity > 0
        )
    ),
  ];

  return (
    <Stack gap="0.5rem">
      {isDisabled && <p className="label-100 text-primary">{label}</p>}

      {!isDisabled && (
        <Stack row gap="0.5rem">
          <InputSelect
            variant="select"
            label={label}
            placeholder={_(
              mode === 'need'
                ? msg({
                    id: 'article.form.actions.placeholder.defect',
                    message: 'Search a need or defect...',
                  })
                : msg({
                    id: 'article.form.actions.placeholder',
                    message: 'Search a care or repair action...',
                  })
            )}
            value={null} // Allows to automatically clear the input when selecting a value
            inputValue={query}
            onInputChange={setQuery}
            options={actionTypeOptions}
            filterOption={null} // Avoid react-select to filter options only based on their label
            getOptionValue={(actionType) => actionType?.id || ''}
            getOptionLabel={(actionType) => {
              if (actionType) {
                const valueToTranslate =
                  'actions' in actionType
                    ? actionType.name
                    : mode === 'need'
                      ? actionType.actionType.needName
                      : actionType.actionType.name;

                return _db(valueToTranslate);
              }

              return '';
            }}
            formatOptionLabel={(actionType) => {
              if (actionType) {
                if ('actions' in actionType) {
                  return (
                    <PackActionDropdownItem
                      key={actionType.id}
                      packAction={actionType}
                      mode={mode}
                      showWorkshopPrice={showWorkshopPrice}
                      showOrganizationPrice={showOrganizationPrice}
                    />
                  );
                }
                return (
                  <ActionDropdownItem
                    key={actionType.id}
                    action={actionType}
                    mode={mode}
                    showWorkshopPrice={showWorkshopPrice}
                    showOrganizationPrice={showOrganizationPrice}
                  />
                );
              }
            }}
            onChange={(actionType) => {
              if (actionType) {
                handleSelectionChange(actionType.id);
              }
            }}
            isLoading={isFetching || query !== debouncedQuery}
            error={error}
            style={{ flex: 1 }}
          />

          {allowCustomActions && (
            <Button
              size="large"
              variant="secondary"
              iconOnly
              ariaLabel={customActionDialogTriggerLabel}
              tooltip={customActionDialogTriggerLabel}
              onPress={() => setIsOpenCustomActionDialog(true)}
              style={{ marginTop: 24, borderColor: error ? 'var(--color-danger-700)' : undefined }}
            >
              <IconAddToList />
            </Button>
          )}
        </Stack>
      )}

      {!isEditActionDisabled && (
        <CustomActionDialog
          isOpen={isOpenCustomActionDialog}
          onClose={handleCloseCustomActionDialog}
          onAddAction={handleAddCustomAction}
          onEditAction={handleEditCustomAction}
          mode={mode}
          showWorkshopPrice={showWorkshopPrice}
          showOrganizationPrice={showOrganizationPrice}
          workshopPriceCurrency={customActionWorkshopPriceCurrency}
          organizationPriceCurrency={customActionOrganizationPriceCurrency}
          initialCustomAction={customActionToEdit}
          isWorkshopPriceDisabled={isEditCustomActionWorkshopPriceDisabled}
        />
      )}

      {(articleActions.length > 0 ||
        articleCustomActions.length > 0 ||
        articlePackActions.length > 0) && (
        <ActionsTable
          actions={articleActions}
          onDecrementAction={handleDecrementAction}
          onIncrementAction={handleIncrementAction}
          onChangeActionResponsibility={handleChangeActionResponsibility}
          customActions={articleCustomActions}
          onDecrementCustomAction={handleDecrementCustomAction}
          onIncrementCustomAction={handleIncrementCustomAction}
          onChangeCustomActionResponsibility={handleChangeCustomActionResponsibility}
          onEditCustomAction={handleStartEditCustomAction}
          packActions={articlePackActions}
          onDecrementPackAction={handleDecrementPackAction}
          onIncrementPackAction={handleIncrementPackAction}
          onChangePackActionResponsibility={handleChangePackActionResponsibility}
          onUploadDefectPhoto={isRequalification ? handleUploadDefectPhoto : undefined}
          onDeleteDefectPhoto={isRequalification ? handleDeleteDefectPhoto : undefined}
          oldActions={oldArticleActions}
          oldCustomActions={oldArticleCustomActions}
          oldPackActions={oldArticlePackActions}
          isDisabled={isDisabled}
          isEditActionDisabled={isEditActionDisabled}
          mode={mode}
          showWorkshopPrice={showWorkshopPrice}
          showOrganizationPrice={showOrganizationPrice}
          showResponsibility={showResponsibility}
          isRequalification={isRequalification}
        />
      )}

      {isDisabled && error && <Message type="error">{error}</Message>}
    </Stack>
  );
};

export default ActionTypeSearchSelect;
