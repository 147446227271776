import { Plural, Trans } from '@lingui/macro';

import { StatusDueDate } from '@/components/DueDate';
import Stack from '@/design_system/Stack';
import { ShipmentWithRelations } from '@/models/shipment';
import {
  Carrier,
  CreationDate,
  DeliveryDate,
  PickupDate,
  ShipmentRef,
  TotalWeight,
  TrackingNumber,
} from '@/routes/Shipments/Shipment/components/ShipmentInfo/ShipmentInfo';
import useViewPort from '@/utils/useViewport';

const LegacyShipmentInfo = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { isMobile } = useViewPort();

  return (
    <Stack gap={isMobile ? '24px' : '48px'} row>
      <CreationDate shipment={shipment} />
      <ShipmentRef shipment={shipment} />
      <TrackingNumber shipment={shipment} />
      {!isMobile && (
        <Stack>
          <span className="paragraph-200-regular text-secondary">
            <Trans id="shipment.articles.label">Items</Trans>
          </span>
          <span className="paragraph-100-medium">
            <Plural
              id="shipment.articles.count"
              value={shipment.articles.length}
              _0="No items"
              one="# item"
              other="# items"
            />
          </span>
        </Stack>
      )}
      {!!shipment.statusDueAtDate && (
        <StatusDueDate date={shipment.statusDueAtDate} variant="info" />
      )}
      <TotalWeight shipment={shipment} />
      <Carrier shipment={shipment} />
      {shipment.pickupDate && <PickupDate shipment={shipment} />}
      {(shipment.arrivalDate || shipment.updatedEtaDate || shipment.originalEtaDate) && (
        <DeliveryDate shipment={shipment} />
      )}
    </Stack>
  );
};

export default LegacyShipmentInfo;
