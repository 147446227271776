import { ReactNode } from 'react';

import { PageLayoutLeftPartFooter } from '@/components/PageLayout';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import { useCurrentSession } from '@/services/auth';

import { AcceptDispatchAction } from './AcceptDispatchAction';
import { AcceptRequalificationAction } from './AcceptRequalificationAction';
import { AnalyzeArticleAction } from './AnalyzeArticleAction';
import { CompleteClientPickup } from './CompleteClientPickup';
import { DispatchArticleAction } from './DispatchArticleAction';
import { EstimateArticleAction } from './EstimateArticleAction';
import { HandleShipmentIssueAction } from './HandleShipmentIssueAction';
import { RepairArticleAction } from './RepairArticleAction';

const ArticleActions = (props: {
  article: ArticleWithRelations;
  request: RequestWithRelations;
  onActionDone: () => void;
  onCheckErrors: () => boolean;
}) => {
  const { currentSession } = useCurrentSession();
  const { article, request } = props;

  if (article.archived) {
    return null;
  }

  let action: ReactNode | null = null;

  switch (article.task?.type) {
    case 'estimate_article':
      action = <EstimateArticleAction {...props} />;
      break;
    case 'dispatch_article':
      action = <DispatchArticleAction {...props} />;
      break;
    case 'accept_dispatch':
      action = <AcceptDispatchAction {...props} />;
      break;
    case 'handle_shipment_issue':
      action = <HandleShipmentIssueAction {...props} />;
      break;
    case 'accept_requalification':
      action = <AcceptRequalificationAction {...props} />;
      break;
    case 'analyze_article':
      action = <AnalyzeArticleAction {...props} />;
      break;
    case 'repair_article':
      action = <RepairArticleAction {...props} />;
      break;
  }

  const isResponsibleStore =
    request.storeId && currentSession?.stores.map((store) => store.id).includes(request.storeId);

  const isPendingClientPickup =
    article.step?.step === 'transit' &&
    article.step?.config.originType === 'store' &&
    article.step?.config.destinationType === 'client';

  if (isPendingClientPickup && isResponsibleStore) {
    action = <CompleteClientPickup {...props} />;
  }

  if (!action) {
    return null;
  }

  return <PageLayoutLeftPartFooter>{action}</PageLayoutLeftPartFooter>;
};

export default ArticleActions;
