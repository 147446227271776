import { useContext } from 'react';
import { GridList, GridListItem } from 'react-aria-components';
import { useNavigate } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import {
  ArticleNameCell,
  ArticleWorkshopCell,
  BaseMoneyCell,
} from '@/components/ArticlesTableCells';
import {
  ArticleCostCardItem,
  ArticlePriceCardItem,
} from '@/components/ArticlesTableCells/ArticleMoneyCell';
import {
  ArticleServiceTypeCardItem,
  ArticleServiceTypeCell,
} from '@/components/ArticlesTableCells/ArticleServiceTypeCell';
import { ArticleWorkshopCardItem } from '@/components/ArticlesTableCells/ArticleWorkshopCell';
import { ArticleStepper } from '@/components/ArticleStepper/ArticleStepper';
import { Card, CardContent, CardItems } from '@/components/Card/Card';
import Stack from '@/design_system/Stack';
import Table from '@/design_system/Table';
import { Body, Cell, Column, Header, Row } from '@/design_system/Table/Table';
import IconChevron from '@/icons/Chevron.svg';
import { useArticleName } from '@/models/article';
import { ArticleWithRelations } from '@/models/request';
import { RequestArticlesContext } from '@/routes/Requests/Request/components/RequestArticles/RequestArticles';
import { createBEMClasses } from '@/utils/classname';

import './ArchivedArticlesTable.css';

const { block, element } = createBEMClasses('archived-articles-table');

export const ArchivedArticlesTable = ({ articles }: { articles: ArticleWithRelations[] }) => {
  const { _ } = useLingui();

  return (
    <Table
      aria-label={_(msg({ id: 'request.articles.table.label', message: 'Items' }))}
      className={block()}
      extraSidePadding
    >
      <ArchivedArticlesTableHeader />
      <Body>
        {articles.map((article) => (
          <ArchivedArticlesTableRow key={article.id} article={article} />
        ))}
      </Body>
    </Table>
  );
};

const ArchivedArticlesTableHeader = () => {
  const { isWorkshop, organizationPriceLabel, workshopPriceLabel } =
    useContext(RequestArticlesContext);

  return (
    <Header noRadius>
      <Column isRowHeader width="1fr" minWidth={200}>
        <Trans id="request.articles.table.label">Items</Trans>
      </Column>
      <Column width="1fr" minWidth={140}>
        <Trans id="request.articles.table.column.step.label">Step</Trans>
      </Column>
      <Column width="1fr" minWidth={128}>
        <Trans id="request.articles.table.column.service-type.label">Service Type</Trans>
      </Column>
      {!isWorkshop && (
        <Column width="1fr" minWidth={160}>
          <Trans id="request.articles.table.column.workshop.label">Workshop</Trans>
        </Column>
      )}
      <Column width="1fr" minWidth={100} align="end">
        {isWorkshop ? workshopPriceLabel : organizationPriceLabel}
      </Column>
    </Header>
  );
};

const ArchivedArticlesTableRow = ({ article }: { article: ArticleWithRelations }) => {
  const { isWorkshop } = useContext(RequestArticlesContext);

  return (
    <Row
      key={article.id}
      id={article.id}
      style={{ opacity: 0.3 }}
      href={`/requests/${article.requestId}/articles/${article.id}`}
    >
      <Cell>
        <ArticleNameCell article={article} />
      </Cell>
      <Cell align="stretch">
        <ArticleStepper size="medium" article={article} mode="step" />
      </Cell>
      <Cell>
        <ArticleServiceTypeCell article={article} />
      </Cell>
      {!isWorkshop && (
        <Cell>
          <ArticleWorkshopCell article={article} showWorkshopPrice />
        </Cell>
      )}
      <Cell align="end">
        <BaseMoneyCell price={article.price} />
      </Cell>
    </Row>
  );
};

export const ArchivedArticlesCardList = ({ articles }: { articles: ArticleWithRelations[] }) => {
  const { _ } = useLingui();
  const navigate = useNavigate();
  const { request } = useContext(RequestArticlesContext);

  return (
    <GridList
      className={block()}
      onAction={(key) => {
        navigate(`/requests/${request.id}/articles/${key}`);
      }}
      aria-label={_(
        msg({
          id: 'request.articles.table.archived-articles.label',
          message: 'Archived items',
        })
      )}
    >
      {articles.map((article) => (
        <ArchivedArticlesCard key={article.id} article={article} />
      ))}
    </GridList>
  );
};

const ArchivedArticlesCard = ({ article }: { article: ArticleWithRelations }) => {
  const { isWorkshop, organizationCostLabel, organizationPriceLabel, workshopPriceLabel } =
    useContext(RequestArticlesContext);

  const articleName = useArticleName({ article });

  return (
    <GridListItem
      id={article.id}
      textValue={articleName}
      style={{ opacity: 0.3 }}
      className={element('item')}
    >
      <Card>
        <CardContent>
          <Stack
            row
            gap="0.5rem"
            flexWrap="nowrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <ArticleNameCell id={article.id} article={article} />
            <IconChevron right style={{ fontSize: '1.5rem' }} />
          </Stack>
          <ArticleStepper size="medium" article={article} mode="step" />
          <CardItems>
            {article.hasActions && <ArticleServiceTypeCardItem article={article} />}
            {!isWorkshop && (
              <>
                <ArticleWorkshopCardItem article={article} />
                <ArticleCostCardItem article={article} costLabel={organizationCostLabel} />
              </>
            )}
            <ArticlePriceCardItem
              article={article}
              priceLabel={isWorkshop ? workshopPriceLabel : organizationPriceLabel}
            />
          </CardItems>
        </CardContent>
      </Card>
    </GridListItem>
  );
};
