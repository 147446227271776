import { useState } from 'react';
import { Separator } from 'react-aria-components';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Accordion from '@/design_system/Accordion';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Message from '@/design_system/Message/Message';
import PhotoCard from '@/design_system/PhotoCard';
import Stack from '@/design_system/Stack';
import IconCalendar from '@/icons/Calendar.svg';
import IconCloth from '@/icons/Cloth.svg';
import IconDownload from '@/icons/Download.svg';
import IconEdit from '@/icons/Edit.svg';
import IconValidation from '@/icons/Validation.svg';
import { useMedia } from '@/models/medium';
import {
  PRODUCT_CATEGORIES,
  PRODUCT_CATEGORIES_L2,
  PRODUCT_MATERIALS,
  useColorName,
  useColorOptions,
  useSizeOptions,
} from '@/models/product';
import { type ArticleWithRelations, type RequestWithRelations } from '@/models/request';
import { ArticlePriceWithTitle } from '@/routes/Requests/Request/Article/ArticlePrice';
import { useCurrentSession } from '@/services/auth';
import { formatDate } from '@/utils/date';
import useViewPort from '@/utils/useViewport';

import { ArticleInformationFormDrawer } from './ArticleInformationFormDrawer';
import { ArticleInformationFormModal } from './ArticleInformationFormModal';
import { useArticleErrors } from './useArticleErrors';

export const ArticleInfoSidePanel = ({
  article,
  request,
  showErrors,
}: {
  article: ArticleWithRelations;
  request: RequestWithRelations;
  showErrors: boolean;
}) => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();
  const [isOpenInformationFormDrawer, setIsOpenInformationFormDrawer] = useState(false);

  const canEstimateArticle = currentSession?.hasPermission('estimate_article', {
    organizationId: article?.organizationId,
  });

  const isUserEstimating =
    article?.legacyStep === 'estimation' && canEstimateArticle && !article.archived;

  const allowWarranty =
    article.steps?.find(
      (step) =>
        ['done', 'in-progress'].includes(step.status) &&
        !!step.config &&
        'allowWarranty' in step.config &&
        step.config.allowWarranty
    ) ?? false;

  return (
    <Stack gap="1rem">
      {!isUserEstimating && (
        <>
          <ArticlePriceWithTitle article={article} request={request} allowDiscount={false} />
          <Separator />
        </>
      )}

      <Stack ariaLabel={_(msg({ id: 'article.information.title', message: 'Information' }))}>
        <Accordion
          openByDefault
          trigger={
            <Stack row gap="0.5rem" alignItems="center">
              <p className="paragraph-100-medium">
                <Trans id="article.information.title">Information</Trans>
              </p>
            </Stack>
          }
        >
          <ArticleDetails article={article} request={request} showErrors={showErrors} />
          {isUserEstimating && (
            <Button
              variant="secondary"
              onPress={() => setIsOpenInformationFormDrawer(true)}
              size="small"
              ariaLabel={_(msg({ id: 'article.edit.actions.edit-article', message: 'Edit' }))}
            >
              <IconEdit />
              <Trans id="article.edit.actions.edit-article">Edit</Trans>
            </Button>
          )}
        </Accordion>

        <ArticleInformationFormDrawer
          request={request}
          article={article}
          isOpen={isOpenInformationFormDrawer}
          closeArticleDrawer={() => setIsOpenInformationFormDrawer(false)}
          showErrors={showErrors}
        />
      </Stack>
      {allowWarranty && !isUserEstimating && (
        <>
          <Separator />

          <Stack>
            <Accordion
              openByDefault
              trigger={
                <Stack row gap="0.5rem" alignItems="center">
                  <p className="paragraph-100-medium">
                    <Trans id="article.warranty.title">Warranty</Trans>
                  </p>
                </Stack>
              }
            >
              <ArticleWarranty article={article} />
            </Accordion>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export const ArticleInfoTopPanel = ({
  article,
  request,
  showErrors,
}: {
  article: ArticleWithRelations;
  request: RequestWithRelations;
  showErrors: boolean;
}) => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();
  const [isOpenInformationFormModal, setIsOpenInformationFormModal] = useState(false);

  const canEstimateArticle = currentSession?.hasPermission('estimate_article', {
    organizationId: article?.organizationId,
  });

  const isUserEstimating =
    article?.legacyStep === 'estimation' && canEstimateArticle && !article.archived;

  const allowWarranty =
    article.steps?.find(
      (step) =>
        ['done', 'in-progress'].includes(step.status) &&
        !!step.config &&
        'allowWarranty' in step.config &&
        step.config.allowWarranty
    ) ?? false;

  return (
    <Stack gap="1rem" className="paragraph-100-regular">
      {!isUserEstimating && (
        <Stack>
          <ArticlePriceWithTitle article={article} request={request} allowDiscount={false} />
        </Stack>
      )}

      <Box padding="0">
        <Stack
          padding="0.75rem"
          ariaLabel={_(msg({ id: 'article.information.title', message: 'Information' }))}
        >
          <Accordion
            trigger={
              <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
                <IconCloth />
                <Trans id="article.information.title">Information</Trans>
              </Stack>
            }
          >
            <Stack style={{ marginTop: '0.5rem' }} gap="1rem">
              <ArticleDetails article={article} request={request} showErrors={showErrors} />
              {isUserEstimating && (
                <Button
                  variant="secondary"
                  size="small"
                  onPress={() => setIsOpenInformationFormModal(true)}
                  ariaLabel={_(msg({ id: 'article.information.edit', message: 'Edit' }))}
                >
                  <IconEdit />
                  <Trans id="article.information.edit">Edit</Trans>
                </Button>
              )}
            </Stack>
          </Accordion>
        </Stack>

        <ArticleInformationFormModal
          request={request}
          article={article}
          isOpen={isOpenInformationFormModal}
          closeModal={() => setIsOpenInformationFormModal(false)}
          showErrors={showErrors}
        />
        {allowWarranty && !isUserEstimating && (
          <>
            <Separator />

            <Stack padding="0.75rem" gap="0.5rem">
              <Accordion
                trigger={
                  <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
                    <IconValidation />
                    <Trans id="article.warranty.title">Warranty</Trans>
                  </Stack>
                }
              >
                <Stack style={{ marginTop: '0.5rem' }} gap="1rem">
                  <ArticleWarranty article={article} />
                </Stack>
              </Accordion>
            </Stack>
          </>
        )}
      </Box>
    </Stack>
  );
};

const ArticleDetails = ({
  article,
  request,
  showErrors,
}: {
  article: ArticleWithRelations;
  request: RequestWithRelations;
  showErrors: boolean;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  const l1Label = PRODUCT_CATEGORIES.find(({ id }) => id === article.productL1)?.label;
  const l2Label = PRODUCT_CATEGORIES_L2.find(({ id }) => id === article.productL2)?.label;
  const l3Label = PRODUCT_MATERIALS.find(({ id }) => id === article.productL3)?.label;
  const colorLabel = useColorName(article.data.color);

  const { productError, productL2Error, otherBrandError } = useArticleErrors(article, request);

  const productName = article.product
    ? `${article.product.externalId} - ${article.product.name}` +
      (article.product.data.collection ? ` (${article.product.data.collection})` : '')
    : '-';

  const colorOptions = useColorOptions(article.product);
  const sizeOptions = useSizeOptions(article.product, article.productL1);

  return (
    <Stack gap="0.75rem" padding="0.5rem 0 0">
      <Stack row gap="1rem" flexWrap="nowrap">
        {!!article.articlePhoto && !isMobile && (
          <PhotoCard
            url={article.articlePhoto.url}
            name={_(msg({ id: 'article.information.article-photo', message: 'Item photo' }))}
            alt={_(msg({ id: 'article.information.article-photo', message: 'Item photo' }))}
            imgStyle={{
              height: '7.5rem',
              width: '6.125rem',
            }}
          />
        )}
        {!!article.product?.data.image && (
          <div style={{ width: '100%' }}>
            {isMobile && (
              <span className="paragraph-200-regular text-secondary">
                <Trans id="article.information.product-photo">Product photo</Trans>
              </span>
            )}
            <PhotoCard
              url={article.product.data.image}
              name={article.product.data.image}
              alt={article.product.data.image}
              imgStyle={{
                height: '7.5rem',
                width: isMobile ? '100%' : '6.125rem',
              }}
            />
          </div>
        )}
      </Stack>

      <Item
        label={_(msg({ id: 'article.information.article-reference', message: 'Item reference' }))}
        value={productName}
        error={showErrors && !!productError ? productError : undefined}
      />
      <Item
        label={_(
          msg({ id: 'article.information.product-reference', message: 'Product reference' })
        )}
        value={article.product?.data.reference}
      />
      <Item
        label={_(msg({ id: 'article.information.type', message: 'Type' }))}
        value={l1Label ? _(l1Label) : undefined}
        error={showErrors && !!productError ? productError : undefined}
      />
      <Item
        label={_(msg({ id: 'article.information.category', message: 'Category' }))}
        value={l2Label ? _(l2Label) : undefined}
        error={showErrors && !!productL2Error ? productL2Error : undefined}
      />
      <Item
        label={_(msg({ id: 'article.information.collection', message: 'Collection' }))}
        value={article.product?.data.collection}
      />
      {!!request.organization.brands.length && (
        <Item
          label={_(msg({ id: 'article.information.brand', message: 'Brand' }))}
          value={article.data.brand?.name}
          error={showErrors && !!otherBrandError ? otherBrandError : undefined}
        />
      )}
      <Item
        label={_(msg({ id: 'article.information.material', message: 'Material' }))}
        value={l3Label ? _(l3Label) : undefined}
      />
      <Item
        label={_(msg({ id: 'article.information.color', message: 'Color' }))}
        value={colorOptions.length > 0 ? colorLabel : 'N/A'}
      />
      <Item
        label={_(msg({ id: 'article.information.size', message: 'Size' }))}
        value={sizeOptions.length > 0 ? article.data.size : 'N/A'}
      />
    </Stack>
  );
};

const ArticleWarranty = ({ article }: { article: ArticleWithRelations }) => {
  const { _ } = useLingui();
  const { data: { media: proofOfPurchaseMedia } = { media: [] } } = useMedia({
    articleId: article.id,
    types: ['proof-of-purchase'],
    limit: 1,
  });

  const proofOfPurchase = proofOfPurchaseMedia?.[0];

  return (
    <Stack gap="0.75rem">
      <Item label={_(msg({ id: 'article.information.purchase-date', message: 'Purchase date' }))}>
        <Stack row gap="0.25rem" alignItems="center">
          <IconCalendar />
          <span
            className="paragraph-100-medium"
            aria-label={_(
              msg({ id: 'article.information.purchase-date', message: 'Purchase date' })
            )}
          >
            {article.purchaseDate
              ? formatDate(new Date(article.purchaseDate), { dateStyle: 'medium' })
              : '-'}
          </span>
        </Stack>
      </Item>
      <Item
        label={_(
          msg({ id: 'article.information.proof-of-purchase', message: 'Proof of purchase' })
        )}
      >
        {proofOfPurchase && (
          <Stack row flexWrap="nowrap" gap="0.25rem">
            <span className="paragraph-100-medium text-primary text-ellipsis">
              {proofOfPurchase.originalPath}
            </span>
            <Button
              href={proofOfPurchase.url}
              target="_blank"
              rel="noreferrer"
              variant="secondary"
              iconOnly
              size="small"
              tooltip={_(msg({ id: 'article.information.download', message: 'Download' }))}
            >
              <IconDownload className="text-secondary" />
            </Button>
          </Stack>
        )}
      </Item>

      <Item
        label={_(msg({ id: 'article.information.warranty.label', message: 'Warranty' }))}
        value={
          article.warranty
            ? _(msg({ id: 'design-system.radio-group.yes-no.yes', message: 'Yes' }))
            : _(msg({ id: 'design-system.radio-group.yes-no.no', message: 'No' }))
        }
      />
    </Stack>
  );
};

const Item = ({
  label,
  value,
  error,
  children,
}: {
  label: string;
  value?: string;
  error?: string;
  children?: React.ReactNode;
}) => {
  return (
    <Stack>
      <span
        className="paragraph-200-regular text-secondary"
        style={error ? { color: 'var(--color-danger-700)' } : undefined}
      >
        {label}
      </span>
      {!children && (
        <span className="paragraph-100-medium" aria-label={label}>
          {value || '-'}
        </span>
      )}
      {children}
      {error && <Message type="error">{error}</Message>}
    </Stack>
  );
};
