import { Trans } from '@lingui/macro';

import { GoToCommentsButton } from '@/components/comments/GoToCommentsButton/GoToCommentsButton';
import Stack from '@/design_system/Stack';
import { ShipmentWithRelations, useComments } from '@/models/shipment';
import {
  EditShipmentButton,
  useEditShipment,
} from '@/routes/Shipments/Shipment/components/ShipmentHeader/EditShipmentButton';
import {
  PrintItemsSheetsButton,
  PrintShippingLabelButton,
  usePrintActions,
} from '@/routes/Shipments/Shipment/components/ShipmentHeader/PrintsButton';
import { useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

const LegacyShipmentHeader = ({
  shipment,
  onCommentButtonPress,
}: {
  shipment: ShipmentWithRelations;
  onCommentButtonPress: () => void;
}) => {
  const { isMobile } = useViewPort();
  const { currentSession } = useCurrentSession();
  const { data: { comments } = { comments: [] } } = useComments({
    shipmentId: shipment.id,
  });

  const canEditShipment = shipment.canBeCreatedBy(currentSession) && shipment.status === 'draft';

  const { editShipment } = useEditShipment({ shipment });
  const { printShippingLabel, printItemsSheets } = usePrintActions({ shipment });

  return (
    <Stack
      gap={isMobile ? '4px' : '24px'}
      alignItems="center"
      row
      justifyContent="space-between"
      flexWrap="nowrap"
    >
      <h1 className="headline-200-bold headline-300-bold-mobile">
        <Trans id="shipment.title">Shipment {shipment.reference}</Trans>
      </h1>
      <Stack gap="8px" row>
        {canEditShipment && <EditShipmentButton onPress={editShipment} />}
        <GoToCommentsButton
          onCommentButtonPress={onCommentButtonPress}
          nbOfComments={comments.length}
        />
        {shipment.status !== 'draft' && <PrintShippingLabelButton onPress={printShippingLabel} />}
        {shipment.step === 'preparation' && <PrintItemsSheetsButton onPress={printItemsSheets} />}
      </Stack>
    </Stack>
  );
};

export default LegacyShipmentHeader;
