import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import PhotoCard from '@/design_system/PhotoCard';
import Stack from '@/design_system/Stack';
import { useArticleName } from '@/models/article';
import {
  ArticleWithRelations as ArticleWithRelationsFromRequest,
  ClientArticleWithRelations,
} from '@/models/request';
import { ArticleWithRelations as ArticleWithRelationsFromShipment } from '@/models/shipment';
import useViewPort from '@/utils/useViewport';

const ArticleNameCell = ({
  id,
  article,
  badge,
  noLink,
}: {
  id?: string;
  article?:
    | ArticleWithRelationsFromRequest
    | ClientArticleWithRelations
    | ArticleWithRelationsFromShipment;
  badge?: ReactNode;
  noLink?: boolean;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const name = useArticleName({ article });

  const photoLabel = _(
    msg({
      id: 'article.photo.label',
      message: `Photo of ${name}`,
    })
  );

  return (
    <Stack
      row
      gap={isMobile ? '0.5rem' : '1rem'}
      alignItems="center"
      flexWrap="nowrap"
      style={{ minWidth: 0 }}
    >
      <div>
        <PhotoCard
          url={article?.articlePhoto?.url}
          name={photoLabel}
          alt={photoLabel}
          imgStyle={{
            height: isMobile ? '2.5rem' : '3.5rem',
            width: isMobile ? '2.5rem' : '3.5rem',
          }}
          size={isMobile ? 'small' : 'medium'}
          hideMoreIcon
        />
      </div>
      <div style={{ overflow: 'hidden' }}>
        <Stack row alignItems="center" gap="0.5rem" flexWrap="nowrap">
          {id && !noLink ? (
            <NavLink to={`articles/${id}`} className="reset-link">
              <p className="paragraph-100-medium text-ellipsis" title={name}>
                {name}
              </p>
            </NavLink>
          ) : (
            <p className="paragraph-100-medium text-ellipsis" title={name}>
              {name}
            </p>
          )}
          {badge}
        </Stack>

        {article?.product?.data.reference && (
          <p className="paragraph-200-regular text-secondary">
            <Trans id="article.reference.short">Ref:</Trans>{' '}
            <span>{article.product.data.reference}</span>
          </p>
        )}

        <Stack row gap="0.25rem">
          {article?.data.color && (
            <p className="paragraph-200-regular text-secondary">
              <span>{article.data.color}</span>
            </p>
          )}
          {article?.data.color && article?.data.size && (
            <p className="paragraph-200-regular text-secondary">
              <span>•</span>
            </p>
          )}
          {article?.data.size && (
            <p className="paragraph-200-regular text-secondary">
              <Trans id="article.size">Size:</Trans> <span>{article.data.size}</span>
            </p>
          )}
        </Stack>
      </div>
    </Stack>
  );
};

export default ArticleNameCell;
