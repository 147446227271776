import { Trans } from '@lingui/macro';

import AlertBar from '@/design_system/AlertBar';
import IconTruck from '@/icons/Truck.svg';
import { RequestWithRelations } from '@/models/request';

const TransitMessage = ({ request }: { request: RequestWithRelations }) => {
  const areAllArticlesInPendingClientPickup = request.articles.every(
    (article) =>
      article.step?.step === 'transit' &&
      article.step?.config.originType === 'store' &&
      article.step?.config.destinationType === 'client'
  );

  return (
    <AlertBar
      type="info"
      size="large"
      icon={<IconTruck />}
      title={
        <Trans id="requests.pending-client-delivery-message.title">Waiting for client pickup</Trans>
      }
    >
      <p>
        {areAllArticlesInPendingClientPickup ? (
          <Trans id="requests.pending-client-delivery-message.text">
            The package has arrived at the store and the client has been notified that they can pick
            up the items.
          </Trans>
        ) : (
          <Trans id="requests.pending-client-delivery-message.text.some">
            Some items have arrived at the store and the client has been notified that they can pick
            them up.
          </Trans>
        )}
      </p>
    </AlertBar>
  );
};

export default TransitMessage;
