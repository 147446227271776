import { ReactNode } from 'react';
import { Plural, Trans } from '@lingui/macro';

import ContactDetails from '@/components/ContactDetails';
import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import IconPackage from '@/icons/Package.svg';
import IconShipmentDone from '@/icons/ShipmentDone.svg';
import IconTruck from '@/icons/Truck.svg';
import { ClientRequestWithRelations } from '@/models/request';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientInfo from '@/routes/Brand/Requests/Request/components/shared/ClientInfo';
import ClientStepper from '@/routes/Brand/Requests/Request/components/shared/ClientStepper';
import Invoice from '@/routes/Brand/Requests/Request/components/shared/Invoice';
import { PickupPointInfo } from '@/routes/Brand/Requests/Request/components/shared/PickupPointInfo/PickupPointInfo';
import Refused from '@/routes/Brand/Requests/Request/components/shared/Refused';
import { createBEMClasses } from '@/utils/classname';

import './Delivery.css';

const { block } = createBEMClasses('client-request-delivery');

const Delivery = ({ request }: { request: ClientRequestWithRelations }) => {
  let messageContent: ReactNode | null = null;

  if (request.isInPendingClientDeliveryStep && request.isCancelled) {
    return <Refused request={request} />;
  }

  if (request.isInPendingDeliveryStep) {
    messageContent = (
      <Stack row gap="0.5rem" flexWrap="nowrap">
        <IconPackage className="color-tertiary-700" style={{ fontSize: '24px' }} />
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          {request.client?.deliveryOption === 'store' && (
            <Trans id="client.request.delivery.pending.store">
              The workshop is preparing your{' '}
              <Plural value={request.articles.length} one="item" other="items" /> for its shipment
              back to the store.
            </Trans>
          )}
          {request.client?.deliveryOption !== 'store' && (
            <Trans id="client.request.delivery.pending.client">
              The workshop is preparing your{' '}
              <Plural value={request.articles.length} one="item" other="items" /> for its shipment
              back to you.
            </Trans>
          )}
        </p>
      </Stack>
    );
  } else if (request.isInTransitDeliveryStep || request.isInReceivedDeliveryStep) {
    messageContent = (
      <Stack row gap="0.5rem" flexWrap="nowrap">
        <IconTruck className="color-tertiary-700" style={{ fontSize: '24px' }} />
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          {request.client?.deliveryOption === 'store' && (
            <Trans id="client.request.delivery.in-transit.store">
              Your <Plural value={request.articles.length} one="item is" other="items are" /> on its
              way back to the store.
            </Trans>
          )}
          {request.client?.deliveryOption !== 'store' && (
            <Trans id="client.request.delivery.in-transit.client">
              Your <Plural value={request.articles.length} one="item is" other="items are" /> on its
              way back to you.
            </Trans>
          )}
        </p>
      </Stack>
    );
  } else if (request.isInPendingClientDeliveryStep) {
    messageContent = (
      <>
        <Stack row gap="0.5rem" flexWrap="nowrap">
          <IconShipmentDone className="color-primary-700" style={{ fontSize: '24px' }} />
          <p className="paragraph-50-regular paragraph-100-regular-mobile">
            <Trans id="client.request.delivery.pending-client">
              Your <Plural value={request.articles.length} one="item has" other="items have" /> been
              successfully delivered to the <b>{request.store?.name} store</b>. You can come to pick{' '}
              <Plural value={request.articles.length} one="it" other="them" /> up at any time during
              the store&apos;s opening hours.
            </Trans>
          </p>
        </Stack>
        <Box>
          <ContactDetails
            type="store"
            name={request.store?.name}
            email={request.store?.address.contactEmail}
            address={request.store?.address}
            phone={request.store?.phone}
          />
        </Box>
      </>
    );
  }

  return (
    <Stack gap="1.5rem" className={block()}>
      <ClientStepper step="delivery" request={request} />
      {messageContent}
      <Stack gap="1rem">
        <PickupPointInfo request={request} />
        <ClientArticlesTable request={request} showPrice showArticleComment />
        <ClientInfo request={request} canEdit={false} />
        {!request.isCancelled && <Invoice request={request} />}
      </Stack>
    </Stack>
  );
};

export default Delivery;
