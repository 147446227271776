import { Trans } from '@lingui/macro';

import RefashionLogo from '@/components/RefashionLogo';
import Button from '@/design_system/Button';
import Tooltip from '@/design_system/Tooltip';
import IconBandage from '@/icons/Bandage.svg';
import IconTask from '@/icons/Task.svg';
import { ArticleAction, ArticleCustomAction, ArticlePackAction } from '@/models/request';
import { useDatabaseTranslation } from '@/models/translation';
import { useClientToken, useCurrentOrganization, useCurrentSession } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';

import './ArticleActionsCell.css';

const { block, element } = createBEMClasses('article-actions-cell');

const ArticleActionsCell = ({
  actions,
  customActions,
  packActions,
  mode,
  showBonus,
}: {
  actions: ArticleAction[];
  customActions: ArticleCustomAction[];
  packActions: ArticlePackAction[];
  mode: 'need' | 'action';
  showBonus?: boolean;
}) => {
  const { _db } = useDatabaseTranslation();
  const clientToken = useClientToken();
  const storeToken = useClientToken();

  const priceWithPossibleBonus = clientToken || storeToken ? 'cost' : 'price';

  return (
    <ul className={block()}>
      {packActions.map((packAction) => (
        <li key={packAction.id} className="paragraph-100-regular">
          <ActionIcon brandResponsibility={packAction.brandResponsibility} mode={mode} />
          <p>
            {_db(packAction.packActionTypeOrganization.name)}
            {packAction.quantity > 1 && ` (x${packAction.quantity})`}
            {showBonus &&
              !!packAction[priceWithPossibleBonus]?.details.find(
                (detail) =>
                  detail.type === 'discount' &&
                  detail.subType === 'refashion' &&
                  detail.amount !== 0
              ) && (
                <>
                  {' '}
                  <RefashionLogo />
                </>
              )}
          </p>
        </li>
      ))}
      {actions.map((action) => (
        <li key={action.id} className="paragraph-100-regular">
          <ActionIcon brandResponsibility={action.brandResponsibility} mode={mode} />
          <p>
            {_db(
              mode === 'need'
                ? action.actionTypeOrganization.actionType.needName
                : action.actionTypeOrganization.actionType.name
            )}
            {action.quantity > 1 && ` (x${action.quantity})`}
            {showBonus &&
              !!action[priceWithPossibleBonus]?.details.find(
                (detail) =>
                  detail.type === 'discount' &&
                  detail.subType === 'refashion' &&
                  detail.amount !== 0
              ) && (
                <>
                  {' '}
                  <RefashionLogo />
                </>
              )}
          </p>
        </li>
      ))}
      {customActions.map((customAction) => (
        <li key={customAction.id} className="paragraph-100-regular">
          <ActionIcon brandResponsibility={customAction.brandResponsibility} mode={mode} />
          {customAction.description}
          {customAction.quantity > 1 && ` (x${customAction.quantity})`}
        </li>
      ))}
    </ul>
  );
};

const ActionIcon = ({
  brandResponsibility,
  mode,
}: {
  brandResponsibility?: boolean;
  mode: 'need' | 'action';
}) => {
  const [organization] = useCurrentOrganization();
  const { currentSession } = useCurrentSession();

  const isFromCurrentOrganization = !!currentSession?.organizations.find(
    ({ id }) => id === organization?.id
  );

  if (brandResponsibility && !!organization && isFromCurrentOrganization) {
    return (
      <Tooltip
        content={
          <Trans id="article-actions.brand-responsibility">
            Under {organization.name} responsibility
          </Trans>
        }
        className={element('tooltip')}
      >
        <Button variant="style-less" className={element('icon')}>
          {mode === 'action' && <IconTask />}
          {mode === 'need' && <IconBandage />}
          <div className={element('brand-responsibility')} />
        </Button>
      </Tooltip>
    );
  }

  return (
    <div className={element('icon')}>
      {mode === 'action' && <IconTask />}
      {mode === 'need' && <IconBandage />}
    </div>
  );
};

export default ArticleActionsCell;
