import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Box from '@/design_system/Box';
import InputSearch from '@/design_system/InputSearch';
import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import Pagination from '@/design_system/Pagination';
import Stack from '@/design_system/Stack';
import Table from '@/design_system/Table';
import { Body, Cell, Column, Header, Row } from '@/design_system/Table/Table';
import { Store, useStores } from '@/models/store';
import { useUsers } from '@/models/user';
import { useWorkshops, Workshop } from '@/models/workshop';
import { useCurrentSession } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';
import useDebouncedState from '@/utils/useDebouncedState';

import { InviteUser } from './InviteUser';
import { RemoveUser } from './RemoveUser';
import { UpdateUserRoles } from './UpdateUserRoles';

const USERS_PER_PAGE = 10;

export const Members = () => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();
  const [page, setPage] = useState(1);

  const [searchWorkshop, debouncedSearchWorkshop, setSearchWorkshop] = useDebouncedState('', 300);
  const [workshop, setWorkshop] = useState<Workshop | null>(null);
  const { data: { workshops } = {}, isFetching: isFetchingWorkshops } = useWorkshops({
    internal: true,
    search: debouncedSearchWorkshop,
  });

  const [searchStore, debouncedSearchStore, setSearchStore] = useDebouncedState('', 300);
  const [store, setStore] = useState<Store | null>(null);
  const { data: { stores } = {}, isFetching: isFetchingStores } = useStores({
    internal: true,
    search: debouncedSearchStore,
  });

  const [search, debouncedSearch, setSearch] = useDebouncedState('', 300);
  const { data: { users, meta } = {}, isFetching: isFetchingUsers } = useUsers({
    limit: USERS_PER_PAGE,
    offset: (page - 1) * USERS_PER_PAGE,
    search: debouncedSearch,
    workshopId: workshop?.id,
    storeId: store?.id,
  });

  return (
    <Box padding="24px" gap="24px">
      <Stack row alignItems="center" justifyContent="space-between">
        <h2 className="headline-200-bold">
          <Trans id="settings.users.title">Organization members</Trans>
        </h2>
        <InviteUser />
      </Stack>
      <Stack row alignItems="center" gap="1rem">
        <ErrorBoundary>
          <InputSearch
            placeholder={_(
              msg({ id: 'settings.users.search.placeholder', message: 'Search by name or email' })
            )}
            style={{ flex: 1, minWidth: 175, maxWidth: 500 }}
            size="medium"
            value={search}
            onChange={setSearch}
            isLoading={isFetchingUsers}
          />
          <div style={{ width: '200px' }}>
            <InputSelect
              aria-label={_(
                msg({ id: 'settings.users.filter.workshop', message: 'Filter by workshop' })
              )}
              placeholder={_(
                msg({ id: 'settings.users.filter.workshop', message: 'Filter by workshop' })
              )}
              filterOption={null}
              inputValue={searchWorkshop}
              onInputChange={setSearchWorkshop}
              isLoading={isFetchingWorkshops}
              value={workshop}
              onChange={setWorkshop}
              options={workshops}
              getOptionValue={(workshop) => workshop.id}
              getOptionLabel={(workshop) => workshop.name}
            />
          </div>
          <div style={{ width: '200px' }}>
            <InputSelect
              aria-label={_(msg({ id: 'settings.users.filter.store', message: 'Filter by store' }))}
              placeholder={_(
                msg({ id: 'settings.users.filter.store', message: 'Filter by store' })
              )}
              filterOption={null}
              inputValue={searchStore}
              onInputChange={setSearchStore}
              isLoading={isFetchingStores}
              value={store}
              onChange={setStore}
              options={stores}
              getOptionValue={(store) => store.id}
              getOptionLabel={(store) => store.name}
            />
          </div>
        </ErrorBoundary>
      </Stack>
      <Table aria-label={_(msg({ id: 'settings.users.table.label', message: 'Membres' }))}>
        <Header>
          <Column width="2fr" minWidth={200} isRowHeader>
            <Trans id="settings.users.column.name">Name</Trans>
          </Column>
          <Column width="2fr" minWidth={200}>
            <Trans id="settings.users.column.email">Email</Trans>
          </Column>
          <Column width="2fr" minWidth={200}>
            <Trans id="settings.users.column.roles">Roles</Trans>
          </Column>
          <Column width={90} />
        </Header>
        <Body>
          {users?.map((user) => (
            <Row key={user.id}>
              <Cell className="sentry-mask">
                <b>{user.name}</b>
              </Cell>
              <Cell className="sentry-mask">{user.email}</Cell>
              <Cell>
                <Stack gap="0.25rem">
                  {user.roles
                    .map((userRole) => {
                      if (userRole.store) {
                        return `${userRole.role.name} (${userRole.store.name})`;
                      }

                      if (userRole.workshop) {
                        return `${userRole.role.name} (${userRole.workshop.name})`;
                      }

                      return userRole.role.name;
                    })
                    .map((label) => (
                      <div key={label}>{label}</div>
                    ))}
                </Stack>
              </Cell>
              <Cell>
                <Stack row gap="4px">
                  <UpdateUserRoles user={user} />
                  {currentSession?.id !== user.id && <RemoveUser user={user} />}
                </Stack>
              </Cell>
            </Row>
          ))}
        </Body>
      </Table>
      <Pagination
        page={page}
        itemsPerPage={USERS_PER_PAGE}
        count={meta?.count}
        onPageChange={setPage}
      />
    </Box>
  );
};
