import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import { useEstimate } from '@/models/article';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import ArticlePrice from '@/routes/Requests/Request/Article/ArticlePrice';
import useViewPort from '@/utils/useViewport';

export const EstimateArticleAction = ({
  article,
  request,
  onActionDone,
  onCheckErrors,
}: {
  article: ArticleWithRelations;
  request: RequestWithRelations;
  onActionDone: () => void;
  onCheckErrors: () => boolean;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const [error, setError] = useState<string | null>(null);

  const {
    mutateAsync: estimateArticle,
    isPending: isPendingEstimate,
    isSuccess: isSuccessEstimate,
  } = useEstimate({
    articleId: article.id,
  });

  const handleEstimate = async () => {
    setError(null);
    const hasErrors = onCheckErrors();

    if (!hasErrors) {
      try {
        await estimateArticle();
        onActionDone();
      } catch (err: any) {
        console.error(err);
        setError(
          (err.message as string) ??
            _(msg({ id: '_general.error.unknown', message: 'Unknown error' }))
        );
      }
    }
  };

  return (
    <Stack gap="1rem">
      {error && <p className="paragraph-100-medium text-danger">{error}</p>}
      <Stack
        row={!isMobile}
        gap={isMobile ? '0.5rem' : '1.5rem'}
        alignItems={isMobile ? 'stretch' : 'center'}
        style={{ flex: '1' }}
        justifyContent="flex-end"
      >
        <Stack row justifyContent={isMobile ? 'flex-end' : 'center'}>
          <ArticlePrice
            request={request}
            article={article}
            allowDiscount={true}
            showWorkshopPrice
            showOrganizationPrice
          />
        </Stack>
        {!isMobile && (
          <div
            style={{ backgroundColor: 'var(--color-neutral-300)', width: '1px', height: '24px' }}
          ></div>
        )}
        <Button
          variant="primary"
          size="medium"
          onPress={handleEstimate}
          isLoading={isPendingEstimate || isSuccessEstimate}
        >
          <Trans id="article.actions.estimate_article.validate">Validate the estimate</Trans>
        </Button>
      </Stack>
    </Stack>
  );
};
