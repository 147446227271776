import Stack from '@/design_system/Stack';
import { ActionTypeOrganizationWithRelations } from '@/models/actionType';
import { useCurrentSession } from '@/services/auth';
import { formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

import ActionName from './ActionName';

export const ActionDropdownItem = ({
  action,
  mode,
  showWorkshopPrice,
  showOrganizationPrice,
}: {
  action: ActionTypeOrganizationWithRelations;
  mode: 'need' | 'action' | 'both';
  showWorkshopPrice?: boolean;
  showOrganizationPrice?: boolean;
}) => {
  const { isMobile } = useViewPort();
  const { currentSession } = useCurrentSession();

  return (
    <Stack
      row={!isMobile}
      alignItems={isMobile ? 'flex-start' : 'center'}
      justifyContent="space-between"
      gap="0.5rem"
      flexWrap="nowrap"
      style={{ flex: 1 }}
    >
      <ActionName
        actionType={action.actionType}
        mode={mode}
        refashionStatus={
          !!action.refashionBonus && !currentSession?.workshop ? 'applied' : undefined
        }
      />
      <Stack
        row
        alignItems="center"
        gap="0.5rem"
        flexWrap="nowrap"
        style={{ alignSelf: isMobile ? 'flex-end' : 'center' }}
      >
        {showWorkshopPrice && (
          <p className="paragraph-100-medium">
            {formatCurrency(action.dynamicCost?.amount, action.dynamicCost?.currency)}
          </p>
        )}
        {showOrganizationPrice && (
          <p className="paragraph-100-medium">
            {formatCurrency(action.dynamicPrice?.amount, action.dynamicPrice?.currency)}
          </p>
        )}
      </Stack>
    </Stack>
  );
};
